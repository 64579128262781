import CONSTANTS from '../actionTypes';
import { dateFormat, getRangeToLoad } from '../util/reservationUtils'
import moment from 'moment'

export default class ReservationAction{
    private _run: any
    private _api: any
    private _message: any
    private _getState: any

    /**
     * @param run
     * @param {Api} api
     * @param {MessageAction} message
     * @param getState
     */
    constructor(run, api, message, getState){
        this._run = run;
        this._api = api;
        this._message = message;
        this._getState = getState
    }

    async reload(){
        const location = this._getState().location
        if(location.type === 'ROUTE_DASHBOARD' || location.type === 'ROUTE_RESERVATION'){
            const {start, end} = getRangeToLoad(moment().format(dateFormat))
            this.loadReservations(start, end)
        }
    }

    async loadReservations(start: Date, end: Date){
        this._run(CONSTANTS.LOAD_RESERVATIONS_ATTEMPT);

        const startStr = encodeURIComponent(start.toISOString())
        const endStr = encodeURIComponent(end.toISOString())

        try{
            const {reservations} = await this._api.request('GET', `/api/reservations?start=${startStr}&end=${endStr}`);
            this._run(CONSTANTS.LOAD_RESERVATIONS_SUCCESS, {reservations, start, end});
        }catch(err){
            this._run(CONSTANTS.LOAD_RESERVATIONS_FAILURE, {err});
            this._message.add('general', 'error', err.message);
        }
    }

    async create(startsAt: Date, endsAt: Date){
        const data = {startsAt, endsAt}
        this._run(CONSTANTS.CREATE_RESERVATION_ATTEMPT);

        try{
            const reservation = await this._api.request('POST', `/api/reservations`, data);
            this._run(CONSTANTS.CREATE_RESERVATION_SUCCESS, {reservation});
        }catch(err){
            this._run(CONSTANTS.CREATE_RESERVATION_FAILURE, {err});
            this._message.add('general', 'error', err.message);
        }
    }

    async remove(id){
        this._run(CONSTANTS.DELETE_RESERVATION_ATTEMPT);

        try{
            await this._api.request('DELETE', `/api/reservations/${encodeURIComponent(id)}`);
            this._run(CONSTANTS.DELETE_RESERVATION_SUCCESS, {id});
        }catch(err){
            this._run(CONSTANTS.DELETE_RESERVATION_FAILURE, {err});
            this._message.add('general', 'error', err.message);
        }
    }
}