import CONSTANTS from '../actionTypes';

export default class DoorAction{
    private _run: any
    private _api: any
    private _message: any

    /**
     * @param run
     * @param {Api} api
     * @param {MessageAction} message
     */
    constructor(run, api, message){
        this._run = run;
        this._api = api;
        this._message = message;
    }

    async openDoor(){
        this._run(CONSTANTS.OPEN_DOOR_ATTEMPT);
        this._message.clear('openDoor');

        try{
            let res = await this._api.request('POST', '/api/opendoor');
            this._run(CONSTANTS.OPEN_DOOR_SUCCESS, {res});
            this._message.add('openDoor', 'info', 'Dør åpen! Fort deg inn!');
        }catch(err){
            this._run(CONSTANTS.OPEN_DOOR_FAILURE, {err});
            this._message.add('openDoor', 'error', err.message);
        }
    }

    async getDoorlog(){
        this._run(CONSTANTS.GET_DOORLOG_ATTEMPT);
        this._message.clear('getDoorlog');

        try{
            let doorlog = await this._api.request('GET', '/api/admin/doorlog');
            this._run(CONSTANTS.GET_DOORLOG_SUCCESS, {doorlog});
        }catch(err){
            this._run(CONSTANTS.GET_DOORLOG_FAILURE, {err});
            this._message.add('getDoorlog', 'error', err.message);
        }
    }
}