import React, {Component} from 'react';

export default (Layout, Doorlog) =>
class RouteAdmin extends Component{
    render(){
        return (
            <Layout>
                <h4>Admin</h4>

                <hr />
                <br />

                <div className="center-block">
                    <a href="http://app.buldrekontoret.com:3005" target="_blank" rel="noopener noreferrer">Truportal admin</a>
                </div>

                <br />
                <hr />
                <br />

                <a href={(process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : '') + "/api/admin/reservations.csv"}>Reservasjoner (csv)</a>

                <br />
                <hr />
                <br />

                <div className="center-block">
                    <Doorlog />
                </div>

            </Layout>
        );
    }
}