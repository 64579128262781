import React, {Component} from 'react';
import ReactModal from 'react-modal';
import ConfirmButton from './ConfirmButton';

/**
 * @param {CupAction} cupAction
 */
export default (cupAction) =>
class CupRouteEditor extends Component{
    constructor(props) {
        super(props);

        let defaults = {
            number: "1",
            gradeId: props.grades[0].id,
            roundId: null
        };

        this.state = {...defaults, ...props.cupRoute};
    }
    onChangeInput(e){
        let state = {};
        state[e.target.name] = e.target.value;
        this.setState(state);
    }
    onSubmit(e){
        e.preventDefault();
        let hasId = !!this.state.id;

        if(hasId){
            cupAction.updateCupRoute(this.state, this.state.id);
        }else{
            cupAction.createCupRoute(this.state);
        }
    }
    onDelete(e){
        e.preventDefault();
        cupAction.destroyCupRoute(this.state.id);
    }
    render(){
        let hasId = !!this.state.id;
        let cupRouteId = this.state.id;
        let apiResult = this.props.apiResult;

        let isCreating = apiResult.createCupRoute.type === 'CREATE_CUP_ROUTE_ATTEMPT' && !hasId;
        let isUpdating = apiResult.updateCupRoute.type === 'UPDATE_CUP_ROUTE_ATTEMPT' && apiResult.updateCupRoute.cupRouteId === cupRouteId;
        let isDeleting = apiResult.destroyCupRoute.type === 'DESTROY_CUP_ROUTE_ATTEMPT' && apiResult.destroyCupRoute.cupRouteId === cupRouteId;
        let isSaving = isCreating || isUpdating;
        let showDeleteButton = hasId;

        return (
            <ReactModal
                className="Modal__Bootstrap modal-dialog"
                closeTimeoutMS={150}
                isOpen={this.props.isOpen}
                onRequestClose={this.props.onClose}
                contentLabel="Cup rute modal"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" onClick={this.props.onClose}>
                            <span aria-hidden="true">&times;</span>
                            <span className="sr-only">Close</span>
                        </button>
                        <h4 className="modal-title">{this.props.title}</h4>
                    </div>

                    <form onSubmit={this.onSubmit.bind(this)}>
                        <div className="modal-body">
                            <div className="form-group">
                                <input name="number" type="text" placeholder="Nr" className="form-control"
                                       value={this.state.number} onChange={this.onChangeInput.bind(this)} />
                            </div>
                            <div className="form-group">
                                <select name="gradeId" className="form-control" value={this.state.gradeId} onChange={this.onChangeInput.bind(this)}>
                                    {this.props.grades.map((grade, key) => {
                                        return <option value={grade.id} key={key}>{grade.colorName} / {grade.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" onClick={this.props.onClose}>Lukk</button>
                            {showDeleteButton &&
                                <div className="pull-left">
                                    {this.renderDeleteButton(isDeleting)}
                                </div>
                            }
                            {this.renderSaveButton(isSaving)}
                        </div>
                    </form>

                </div>
            </ReactModal>
        );
    }
    renderSaveButton(isSaving){
        if(isSaving){
            return <button className="btn btn-success" disabled="disabled">Lagrer...</button>;
        }

        return <button type="submit" className="btn btn-success">Lagre</button>;
    }
    renderDeleteButton(isDeleting){
        if(isDeleting){
            return <button className="btn btn-danger" disabled="disabled">Sletter...</button>;
        }

        return <ConfirmButton className="btn btn-danger" onClick={this.onDelete.bind(this)}>Slett</ConfirmButton>
    }
}