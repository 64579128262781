import React, {Component} from 'react';

export default (Layout, Login) =>
class RouteRegisterConfirm extends Component{
    render(){
        return (
            <Layout>
                {this.props.apiResult.registerConfirm.type === 'REGISTER_CONFIRM_ATTEMPT' &&
                <div className="center-block">
                    <h3>Verifiserer...</h3>
                </div>
                }
                {this.props.apiResult.registerConfirm.type === 'REGISTER_CONFIRM_SUCCESS' &&
                <div className="center-block">
                    <h3>Verifikasjon vellykket!</h3>
                    <Login />
                </div>
                }
                {this.props.apiResult.registerConfirm.type === 'REGISTER_CONFIRM_FAILURE' &&
                <div className="center-block">
                    <h3>{this.props.apiResult.registerConfirm.err.message}</h3>
                </div>
                }
            </Layout>
        );
    }
}