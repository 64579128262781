import CONSTANTS from '../actionTypes';

let initialState = {
    login: {},
    logout: {},
    register: {},
    registerConfirm: {},
    openDoor: {},
    getDoorlog: {},
    checkIn: {},
    getCheckInStatus: {},
    getCheckedIn: {},
    sendPasswordResetEmail: {},
    resetPassword: {},
    getProfile: {},
    getCup: {},

    cupTick: {},
    cupUntick: {},

    createCup: {},
    updateCup: {},
    destroyCup: {},

    createCupRound: {},
    updateCupRound: {},
    destroyCupRound: {},

    createCupRoute: {},
    updateCupRoute: {},
    destroyCupRoute: {}
};

let mapping = {};

mapping[CONSTANTS.LOGIN_ATTEMPT] = 'login';
mapping[CONSTANTS.LOGIN_SUCCESS] = 'login';
mapping[CONSTANTS.LOGIN_FAILURE] = 'login';

mapping[CONSTANTS.LOGOUT_ATTEMPT] = 'logout';
mapping[CONSTANTS.LOGOUT_SUCCESS] = 'logout';
mapping[CONSTANTS.LOGOUT_FAILURE] = 'logout';

mapping[CONSTANTS.REGISTER_ATTEMPT] = 'register';
mapping[CONSTANTS.REGISTER_SUCCESS] = 'register';
mapping[CONSTANTS.REGISTER_FAILURE] = 'register';

mapping[CONSTANTS.REGISTER_CONFIRM_ATTEMPT] = 'registerConfirm';
mapping[CONSTANTS.REGISTER_CONFIRM_SUCCESS] = 'registerConfirm';
mapping[CONSTANTS.REGISTER_CONFIRM_FAILURE] = 'registerConfirm';

mapping[CONSTANTS.OPEN_DOOR_ATTEMPT] = 'openDoor';
mapping[CONSTANTS.OPEN_DOOR_SUCCESS] = 'openDoor';
mapping[CONSTANTS.OPEN_DOOR_FAILURE] = 'openDoor';

mapping[CONSTANTS.CHECKIN_ATTEMPT] = 'checkIn';
mapping[CONSTANTS.CHECKIN_FAILURE] = 'checkIn';
mapping[CONSTANTS.CHECKIN_SUCCESS] = 'checkIn';

mapping[CONSTANTS.GET_CHECKINSTATUS_ATTEMPT] = 'getCheckInStatus';
mapping[CONSTANTS.GET_CHECKINSTATUS_SUCCESS] = 'getCheckInStatus';
mapping[CONSTANTS.GET_CHECKINSTATUS_FAILURE] = 'getCheckInStatus';

mapping[CONSTANTS.GET_CHECKEDIN_ATTEMPT] = 'getCheckedIn';
mapping[CONSTANTS.GET_CHECKEDIN_SUCCESS] = 'getCheckedIn';
mapping[CONSTANTS.GET_CHECKEDIN_FAILURE] = 'getCheckedIn';

mapping[CONSTANTS.RESET_PASSWORD_ATTEMPT] = 'resetPassword';
mapping[CONSTANTS.RESET_PASSWORD_SUCCESS] = 'resetPassword';
mapping[CONSTANTS.RESET_PASSWORD_FAILURE] = 'resetPassword';

mapping[CONSTANTS.SEND_PASSWORD_RESET_EMAIL_ATTEMPT] = 'sendPasswordResetEmail';
mapping[CONSTANTS.SEND_PASSWORD_RESET_EMAIL_SUCCESS] = 'sendPasswordResetEmail';
mapping[CONSTANTS.SEND_PASSWORD_RESET_EMAIL_FAILURE] = 'sendPasswordResetEmail';

mapping[CONSTANTS.GET_DOORLOG_ATTEMPT] = 'getDoorlog';
mapping[CONSTANTS.GET_DOORLOG_SUCCESS] = 'getDoorlog';
mapping[CONSTANTS.GET_DOORLOG_FAILURE] = 'getDoorlog';

mapping[CONSTANTS.GET_PROFILE_ATTEMPT] = 'getProfile';
mapping[CONSTANTS.GET_PROFILE_SUCCESS] = 'getProfile';
mapping[CONSTANTS.GET_PROFILE_FAILURE] = 'getProfile';

mapping[CONSTANTS.GET_CUP_ATTEMPT] = 'getCup';
mapping[CONSTANTS.GET_CUP_SUCCESS] = 'getCup';
mapping[CONSTANTS.GET_CUP_FAILURE] = 'getCup';

mapping[CONSTANTS.CREATE_CUP_ATTEMPT] = 'createCup';
mapping[CONSTANTS.CREATE_CUP_SUCCESS] = 'createCup';
mapping[CONSTANTS.CREATE_CUP_FAILURE] = 'createCup';

mapping[CONSTANTS.UPDATE_CUP_ATTEMPT] = 'updateCup';
mapping[CONSTANTS.UPDATE_CUP_SUCCESS] = 'updateCup';
mapping[CONSTANTS.UPDATE_CUP_FAILURE] = 'updateCup';

mapping[CONSTANTS.DESTROY_CUP_ATTEMPT] = 'destroyCup';
mapping[CONSTANTS.DESTROY_CUP_SUCCESS] = 'destroyCup';
mapping[CONSTANTS.DESTROY_CUP_FAILURE] = 'destroyCup';

mapping[CONSTANTS.CREATE_CUP_ROUND_ATTEMPT] = 'createCupRound';
mapping[CONSTANTS.CREATE_CUP_ROUND_SUCCESS] = 'createCupRound';
mapping[CONSTANTS.CREATE_CUP_ROUND_FAILURE] = 'createCupRound';

mapping[CONSTANTS.UPDATE_CUP_ROUND_ATTEMPT] = 'updateCupRound';
mapping[CONSTANTS.UPDATE_CUP_ROUND_SUCCESS] = 'updateCupRound';
mapping[CONSTANTS.UPDATE_CUP_ROUND_FAILURE] = 'updateCupRound';

mapping[CONSTANTS.DESTROY_CUP_ROUND_ATTEMPT] = 'destroyCupRound';
mapping[CONSTANTS.DESTROY_CUP_ROUND_SUCCESS] = 'destroyCupRound';
mapping[CONSTANTS.DESTROY_CUP_ROUND_FAILURE] = 'destroyCupRound';

mapping[CONSTANTS.CREATE_CUP_ROUTE_ATTEMPT] = 'createCupRoute';
mapping[CONSTANTS.CREATE_CUP_ROUTE_SUCCESS] = 'createCupRoute';
mapping[CONSTANTS.CREATE_CUP_ROUTE_FAILURE] = 'createCupRoute';

mapping[CONSTANTS.UPDATE_CUP_ROUTE_ATTEMPT] = 'updateCupRoute';
mapping[CONSTANTS.UPDATE_CUP_ROUTE_SUCCESS] = 'updateCupRoute';
mapping[CONSTANTS.UPDATE_CUP_ROUTE_FAILURE] = 'updateCupRoute';

mapping[CONSTANTS.DESTROY_CUP_ROUTE_ATTEMPT] = 'destroyCupRoute';
mapping[CONSTANTS.DESTROY_CUP_ROUTE_SUCCESS] = 'destroyCupRoute';
mapping[CONSTANTS.DESTROY_CUP_ROUTE_FAILURE] = 'destroyCupRoute';

mapping[CONSTANTS.CUP_TICK_ATTEMPT] = 'cupTick';
mapping[CONSTANTS.CUP_TICK_SUCCESS] = 'cupTick';
mapping[CONSTANTS.CUP_TICK_FAILURE] = 'cupTick';

mapping[CONSTANTS.CUP_UNTICK_ATTEMPT] = 'cupUntick';
mapping[CONSTANTS.CUP_UNTICK_SUCCESS] = 'cupUntick';
mapping[CONSTANTS.CUP_UNTICK_FAILURE] = 'cupUntick';

export default function(state = initialState, action){
    if(!mapping.hasOwnProperty(action.type)){
        return state;
    }

    let name = mapping[action.type];

    return {...state, [name]: action};
}