import CONSTANTS from '../actionTypes';

export default class AuthAction{
    private _run: any
    private _api: any
    private _message: any

    /**
     * @param run
     * @param {Api} api
     * @param {MessageAction} message
     */
    constructor(run, api, message){
        this._run = run;
        this._api = api;
        this._message = message;
    }

    async login(data){
        this._run(CONSTANTS.LOGIN_ATTEMPT);
        this._message.clear('login');

        try{
            let res = await this._api.request('POST', '/api/login', data);
            this._run(CONSTANTS.LOGIN_SUCCESS, {res});
            this._run(CONSTANTS.ROUTE_DASHBOARD);
        }catch(err){
            this._run(CONSTANTS.LOGIN_FAILURE, {err});
            this._message.add('login', 'error', err.message);
        }
    }

    async loginWithFacebook(data){
        this._run(CONSTANTS.LOGIN_ATTEMPT);
        this._message.clear('login');

        try{
            let res = await this._api.request('POST', '/api/login-facebook', data);
            this._run(CONSTANTS.LOGIN_SUCCESS, {res});
            this._run(CONSTANTS.ROUTE_DASHBOARD);
        }catch(err){
            this._run(CONSTANTS.LOGIN_FAILURE, {err});
            this._message.add('login', 'error', err.message);
        }
    }

    async logout(){
        this._run(CONSTANTS.LOGOUT_ATTEMPT);
        this._message.clear('logout');

        try{
            let res = await this._api.request('POST', '/api/logout');
            this._run(CONSTANTS.LOGOUT_SUCCESS, {res});
            this._run(CONSTANTS.ROUTE_WELCOME);
            this._message.add('general', 'info', 'Du er nå logget ut');
        }catch(err){
            this._run(CONSTANTS.LOGOUT_FAILURE, {err});
            this._message.add('logout', 'error', err.message);
        }
    }
}