import React, {Component} from 'react';

/**
 * @param {PasswordResetAction} passwordResetAction
 * @param {Message} Message
 */
export default (passwordResetAction, Message) =>
class PasswordResetEmailForm extends Component{
    constructor(props) {
        super(props);
        this.state = {
            email: ''
        };
    }
    onChangeInput(e){
        let state = {};
        state[e.target.name] = e.target.value;
        this.setState(state);
    }
    onSubmit(e){
        e.preventDefault();
        passwordResetAction.sendPasswordResetEmail(this.state);
    }
    render(){
        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <div className="panel-title">
                    Passord savnet! <button className="close" onClick={this.props.onClose}><span>&times;</span></button>
                    </div>
                </div>
                <div className="panel-body">
                    <form onSubmit={this.onSubmit.bind(this)}>
                        <div className="form-group">
                            <input name="email" type="email" placeholder="E-post" className="form-control"
                                   value={this.state.email} onChange={this.onChangeInput.bind(this)} />
                        </div>

                        <div className="form-group">
                            {this.props.apiResult.sendPasswordResetEmail.type === 'SEND_PASSWORD_RESET_EMAIL_ATTEMPT' ?
                                <button className="btn btn-default" disabled="disabled">Dekrypterer kardangen...</button>
                                :
                                <button type="submit" className="btn btn-default">Tilbakestill</button>
                            }
                        </div>
                    </form>

                    <Message context="sendPasswordResetEmail" />
                </div>
            </div>
        );
    }
}