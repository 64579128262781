import _ from 'lodash'
import GRADE_SCORES from './gradeScores'

const competitionClasses = [
    {id: 'blackPlus', displayName: 'Svart +', color: 'black',  gradeColors: ['black', 'white', 'silver']},
    {id: 'red',       displayName: 'Rød',    color: 'red',    gradeColors: ['red']},
    {id: 'yellow',    displayName: 'Gul',    color: 'yellow', gradeColors: ['yellow']},
    {id: 'blue',      displayName: 'Blå',    color: 'blue',   gradeColors: ['blue']},
    {id: 'green',     displayName: 'Grønn',  color: 'green',  gradeColors: ['green']},
];

const compClassByGradeColor = competitionClasses.reduce((acc, compClass) => {
    compClass.gradeColors.forEach(gradeColor => (acc[gradeColor] = compClass));
    return acc
}, {});

export default function computeCupResults(routes, grades, ticks){
    let cupRouteById = _.keyBy(routes, 'id');
    let gradeById = _.keyBy(grades, 'id');
    let ticksByUserId = _.groupBy(ticks, 'userId');

    function tickToGrade(tick){
        let cupRoute = cupRouteById[tick.routeId];
        return gradeById[cupRoute.gradeId];
    }

    function getUserResult(userId, ticksThisUser){
        let firstTick = ticksThisUser[0];
        let userName = firstTick.firstname + ' ' + firstTick.lastname;

        let gradesTicked = ticksThisUser.map(tickToGrade);
        let gradeScores = gradesTicked.map(grade => GRADE_SCORES[grade.color]);
        let score = _.sum(gradeScores);
        let highestGrade = _(gradesTicked).sortBy('sortIndex').reverse().first();
        let compClass = compClassByGradeColor[highestGrade.color];

        return {score, grade: highestGrade, compClass, userName, userId};
    }

    function getResultsSortedWithPositions(results){
        return _(results)
            .sortBy('userName')
            .groupBy('score')
            .map((results, score) => ({score: parseInt(score, 10), results}))
            .sortBy('score')
            .reverse()
            .map(({score, results}, i) => {
                return results.map(result => ({...result, position: i + 1}))
            })
            .flatten()
            .value()
    }

    let resultsByCompClassId = Object.keys(ticksByUserId).map(userId => {
        let ticksThisUser = ticksByUserId[userId];
        return getUserResult(userId, ticksThisUser);
    });
    resultsByCompClassId = _.groupBy(resultsByCompClassId, 'compClass.id');

    return competitionClasses.map(compClass => {
        let results = resultsByCompClassId[compClass.id] || [];

        results = getResultsSortedWithPositions(results)

        return {compClass, results}
    })
}