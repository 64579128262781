import React, {Component} from 'react';

/**
 * @param {AuthAction} authAction
 * @param {Message} Message
 */
export default (authAction, Message) =>
class Logout extends Component{
    onClickLogout() {
        authAction.logout();
    }
    render(){
        return (
            <div>
                <div className="form-group">
                    {this.props.apiResult.logout.type === 'LOGOUT_ATTEMPT' ?
                    <button className="btn btn-default" disabled="disabled">Logger ut...</button>
                    :
                    <button className="btn btn-default" onClick={this.onClickLogout.bind(this)}>Logg ut</button>
                    }
                </div>

                <Message context="logout" />

            </div>
        );
    }
}