import CONSTANTS from '../actionTypes'

export default ioc => {
    ioc.service('RouteMap', ioc => {
        return {
            [CONSTANTS.ROUTE_WELCOME]: '/',
            [CONSTANTS.ROUTE_DASHBOARD]: {
                path:'/dashboard',
                thunk: async (dispatch, getState) => {
                    await ioc['ProfileAction'].loadProfile();
                    await ioc['CheckInAction'].getCheckInStatus();
                    await ioc['CheckInAction'].getCheckedIn();
                    ioc['WebSocketClient'].ensureStarted()
                }
            },
            [CONSTANTS.ROUTE_CUP]: {
                path:'/cup/:cupId',
                thunk: async (dispatch, getState) => {
                    const {cupId} = getState().location.payload;
                    await ioc['ProfileAction'].loadProfile();
                    await ioc['GradeAction'].loadGrades();
                    await ioc['CupAction'].loadCup(cupId);
                    ioc['WebSocketClient'].ensureStarted()
                }
            },
            [CONSTANTS.ROUTE_CUP_ROUND_LIVE]: {
                path:'/cup/:cupId/round/:roundId/live',
                thunk: async (dispatch, getState) => {
                    const {cupId} = getState().location.payload;
                    await ioc['GradeAction'].loadGrades();
                    await ioc['CupAction'].loadCup(cupId);
                    ioc['WebSocketClient'].ensureStarted()
                }
            },
            [CONSTANTS.ROUTE_CUP_ROUND]: {
                path:'/cup/:cupId/round/:roundId/:roundTabSelected?',
                thunk: async (dispatch, getState) => {
                    const {cupId} = getState().location.payload;
                    await ioc['ProfileAction'].loadProfile();
                    await ioc['GradeAction'].loadGrades();
                    await ioc['CupAction'].loadCup(cupId);
                    ioc['WebSocketClient'].ensureStarted()
                }
            },
            [CONSTANTS.ROUTE_REGISTER_CONFIRM]: {
                path:'/register/confirm/:code',
                thunk: async (dispatch, getState) => {
                    const {code} = getState().location.payload;
                    await ioc['RegisterAction'].registerConfirm(code);
                }
            },
            [CONSTANTS.ROUTE_RESERVATION]: {
                path:'/reservation',
                thunk: async (dispatch, getState) => {
                    await ioc['ProfileAction'].loadProfile();
                    ioc['WebSocketClient'].ensureStarted()
                }
            },
            [CONSTANTS.ROUTE_PASSWORD_RESET]: '/passwordreset/:token',
            [CONSTANTS.ROUTE_ADMIN]: {
                path:'/admin',
                thunk: async (dispatch, getState) => {
                    await ioc['ProfileAction'].loadProfile();
                    await ioc['DoorAction'].getDoorlog();
                }
            }
        }
    })

    ioc.service('RouteToComponentMap', ioc => {
        return {
            [CONSTANTS.ROUTE_WELCOME]: ioc['RouteWelcome'],
            [CONSTANTS.ROUTE_DASHBOARD]: ioc['RouteDashboard'],
            [CONSTANTS.ROUTE_CUP]: ioc['RouteCup'],
            [CONSTANTS.ROUTE_CUP_ROUND]: ioc['RouteCupRound'],
            [CONSTANTS.ROUTE_CUP_ROUND_LIVE]: ioc['RouteCupRoundLive'],
            [CONSTANTS.ROUTE_REGISTER_CONFIRM]: ioc['RouteRegisterConfirm'],
            [CONSTANTS.ROUTE_PASSWORD_RESET]: ioc['RoutePasswordReset'],
            [CONSTANTS.ROUTE_RESERVATION]: ioc['RouteReservation'],
            [CONSTANTS.ROUTE_ADMIN]: ioc['RouteAdmin']
        }
    })

    // Used to determine which component to render
    ioc.service('routeReducer', ioc => {
        const routes = [
            'ROUTE_WELCOME',
            'ROUTE_DASHBOARD',
            'ROUTE_CUP',
            'ROUTE_CUP_ROUND',
            'ROUTE_CUP_ROUND_LIVE',
            'ROUTE_REGISTER_CONFIRM',
            'ROUTE_PASSWORD_RESET',
            'ROUTE_RESERVATION',
            'ROUTE_ADMIN',
        ]

        return function(state = CONSTANTS.ROUTE_WELCOME, action){
            if(routes.includes(action.type)){
                return action.type;
            }

            return state;
        }
    })
}