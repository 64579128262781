import React, {Component} from 'react';
import computeCupResults from '../util/computeCupResults'
import GRADE_COLORS from '../util/gradeColors';

export default () =>
class CupScoreBoard extends Component{
    render(){
        let results = computeCupResults(this.props.cup.routes, this.props.grades, this.props.ticks)

        return (
            <div className="cup-scoreboard-big">
                {results.reverse().map(({compClass, results}) => {
                    let colorScheme = GRADE_COLORS[compClass.color];

                    return (
                        <div className="cup-scoreboard-wrapper" key={compClass.id}>
                            <div className="cup-scoreboard">
                                <div className="cup-scoreboard-color-title" style={colorScheme}>{compClass.displayName}</div>
                                <div className="cup-scoreboard-color-list">
                                    {results.map(result => {
                                        return (
                                            <div className="cup-scoreboard-row" key={result.userId}>
                                                <div className="cup-scoreboard-col cup-scoreboard-col-place">{result.position}.</div>
                                                <div className="cup-scoreboard-col cup-scoreboard-col-name">{result.userName}</div>
                                                <div className="cup-scoreboard-col cup-scoreboard-col-score">{result.score}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}