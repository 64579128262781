import CONSTANTS from '../actionTypes';

let initialState = {
    cup: {},
    rounds: [],
    routes: [],
    ticks: [],
    roundId: null,
    roundTabSelected: 'results'
};

export default function(state = initialState, action){
    if(action.type === CONSTANTS.GET_CUP_SUCCESS){
        return {...state, ...action.data};
    }

    if(action.type === CONSTANTS.CUP_TICK_SUCCESS){
        return {...state, ticks: [...state.ticks, action.tick]};
    }

    if(action.type === CONSTANTS.ROUTE_CUP_ROUND){
        return {...state, roundId: Number(action.payload.roundId), roundTabSelected: action.payload.roundTabSelected};
    }

    if(action.type === CONSTANTS.ROUTE_CUP_ROUND_LIVE){
        return {...state, roundId: Number(action.payload.roundId)};
    }

    if(action.type === CONSTANTS.CUP_UNTICK_SUCCESS){
        let ticks = state.ticks.filter(tick => tick.id !== action.tickId);
        return {...state, ticks};
    }

    if(action.type === CONSTANTS.CREATE_CUP_ROUND_SUCCESS){
        return {...state, rounds: [...state.rounds, action.cupRound]};
    }

    if(action.type === CONSTANTS.UPDATE_CUP_ROUND_SUCCESS){
        let rounds = state.rounds.filter(round => round.id !== action.cupRound.id);
        rounds = [...rounds, action.cupRound];
        return {...state, rounds};
    }

    if(action.type === CONSTANTS.DESTROY_CUP_ROUND_SUCCESS){
        let rounds = state.rounds.filter(round => round.id !== action.cupRoundId);
        return {...state, rounds};
    }

    if(action.type === CONSTANTS.CREATE_CUP_ROUTE_SUCCESS){
        return {...state, routes: [...state.routes, action.cupRoute]};
    }

    if(action.type === CONSTANTS.UPDATE_CUP_ROUTE_SUCCESS){
        let routes = state.routes.filter(route => route.id !== action.cupRoute.id);
        routes = [...routes, action.cupRoute];
        return {...state, routes};
    }

    if(action.type === CONSTANTS.DESTROY_CUP_ROUTE_SUCCESS){
        let routes = state.routes.filter(route => route.id !== action.cupRouteId);
        return {...state, routes};
    }

    return state;
}