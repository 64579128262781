import React, {Component} from 'react';
import _ from 'lodash';

export default (Layout, CupRoundEditor, CupScoreBoard, CupRoundLink) =>
class RouteCup extends Component{
    constructor(props){
        super(props);
        this.state = {
            showRoundCreator: false
        };
    }
    openRoundCreator(e){
        e.preventDefault();
        this.setState({showRoundCreator: true});
    }
    closeRoundCreator(e){
        e.preventDefault();
        this.setState({showRoundCreator: false});
    }
    render(){
        let {cup, rounds, ticks} = this.props.cup;

        if(!cup.id){
            return null;
        }

        let isAdmin = !!this.props.profile.user.isAdmin;

        rounds = _.sortBy(rounds, 'startsAt');

        return (
            <Layout>
                <h2>Buldrecup {cup.name}</h2>

                <hr />

                {rounds.map(cupRound => {
                    return <CupRoundLink cupRound={cupRound} key={cupRound.id} />
                })}

                {isAdmin &&
                <div>
                    <br />

                    <button className="btn btn-default btn-sm" onClick={this.openRoundCreator.bind(this)}>
                        <span className="fa fa-plus" /> Ny runde
                    </button>

                    <CupRoundEditor
                        title="Ny runde"
                        isOpen={this.state.showRoundCreator}
                        onClose={this.closeRoundCreator.bind(this)}
                        cupRound={{cupId: cup.id}}
                    />

                </div>
                }

                <hr />

                <h2>Resultater sammenlagt</h2>

                <CupScoreBoard ticks={ticks} />

            </Layout>
        );
    }
}