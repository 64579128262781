import React, {Component} from 'react';

/**
 * @param {RegisterAction} registerAction
 * @param {Message} Message
 */
export default (registerAction, Message) =>
class Register extends Component{
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            firstname: '',
            lastname: '',
            tlf: '',
            address: '',
            code: '',
            password: '',
            passwordRepeat: ''
        };
    }
    onChangeInput(e){
        let state = {};
        state[e.target.name] = e.target.value;
        this.setState(state);
    }
    onSubmitRegister(e){
        e.preventDefault();
        registerAction.register(this.state);
    }
    render(){
        return (
            <form onSubmit={this.onSubmitRegister.bind(this)}>
                <div className="form-group">
                    <input name="email" type="email" placeholder="E-post" className="form-control"
                           value={this.state.email} onChange={this.onChangeInput.bind(this)} />
                </div>
                <div className="form-group">
                    <input name="firstname" type="text" placeholder="Fornavn" className="form-control"
                           value={this.state.firstname} onChange={this.onChangeInput.bind(this)} />
                </div>
                <div className="form-group">
                    <input name="lastname" type="text" placeholder="Etternavn" className="form-control"
                           value={this.state.lastname} onChange={this.onChangeInput.bind(this)} />
                </div>
                <div className="form-group">
                    <input name="tlf" type="text" placeholder="Telefon" className="form-control"
                           value={this.state.tlf} onChange={this.onChangeInput.bind(this)} />
                </div>
                <div className="form-group">
                    <input name="address" type="text" placeholder="Adresse" className="form-control"
                           value={this.state.address} onChange={this.onChangeInput.bind(this)} />
                </div>
                <div className="form-group">
                    <input name="code" type="text" placeholder="Hva er kodeordet?" className="form-control"
                           value={this.state.code} onChange={this.onChangeInput.bind(this)} />
                </div>
                <div className="form-group">
                    <input name="password" type="password" placeholder="Passord" className="form-control"
                           value={this.state.password} onChange={this.onChangeInput.bind(this)} />
                </div>
                <div className="form-group">
                    <input name="passwordRepeat" type="password" placeholder="Passord (igjen)" className="form-control"
                           value={this.state.passwordRepeat} onChange={this.onChangeInput.bind(this)} />
                </div>

                <div className="form-group">
                    {this.props.apiResult.register.type === 'REGISTER_ATTEMPT' ?
                        <button className="btn btn-success" disabled="disabled">Registrer...</button>
                        :
                        <button type="submit" className="btn btn-success">Registrer meg</button>
                    }
                </div>

                <Message context="register" />

            </form>
        );
    }
}