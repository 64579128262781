import React from 'react'
import { useSelector } from 'react-redux'
import { User } from '../types'

type SelectedState = {
    user: User
}

const selector = (state): SelectedState => {
    return {
        user: state.profile.user
    }
}

export default (
    Layout,
    DoorOpener,
    CheckIn,
    CheckedIn,
    Logout
) => function RouteDashboard(){
    const {user} = useSelector(selector)

    return (
        <Layout>
            <h4>Hei {user.firstname}!</h4>

            <p>
                Trykk på knappen nedenfor, så åpner døren seg i løpet av få sekunder.
            </p>

            <div className="center-block">
                <DoorOpener />
            </div>

            <br />
            <hr />
            <br />

            <p>
                Annonser din tilstedeværelse på kontoret, sjekk inn!
            </p>

            <div className="center-block">
                <CheckIn />
            </div>

            <div className="center-block">
                <CheckedIn />
            </div>

            <br />
            <hr />
            <br />

            <div className="center-block">
                <Logout />
            </div>

        </Layout>
    );
}