import { combineReducers } from 'redux';

import apiResult from './reducers/apiResult';
import profile from './reducers/profile';
import cup from './reducers/cup';
import grades from './reducers/grades';
import message from './reducers/message';
import checkInStatus from './reducers/checkInStatus';
import checkedIn from './reducers/checkedIn';
import doorlog from './reducers/doorlog';
import passwordResetToken from './reducers/passwordResetToken';
import reservation from './reducers/reservation';

export default function(otherReducers = {}){
    const reducer = combineReducers({
        apiResult,
        profile,
        cup,
        grades,
        message,
        checkInStatus,
        checkedIn,
        doorlog,
        passwordResetToken,
        reservation,
        ...otherReducers
    });

    return (state, action) => {
        if(action.type === 'LOGOUT_SUCCESS'){
            state = undefined
        }

        return reducer(state, action)
    };
}