import React, {Component} from 'react';

export default (Layout, Login, Register, PasswordResetEmailForm) =>
class RouteWelcome extends Component{
    constructor(props){
        super(props);
        this.state = {
            showPasswordRestForm: false
        };
    }
    togglePasswordResetForm(e){
        e.preventDefault();
        this.setState(state => ({showPasswordRestForm: !state.showPasswordRestForm}));
    }
    render() {
        return (
            <Layout>
                <p>
                    Her kan du åpne døra til buldrekontoret.
                    Om du ikke har gjort det allerede må du registrere deg nedenfor, eller logge inn med facebook.
                </p>

                <hr />

                <div>
                    <div className="center-block">
                        <h3>Jeg er registrert</h3>
                        <Login />

                        {this.state.showPasswordRestForm ?
                            <PasswordResetEmailForm onClose={this.togglePasswordResetForm.bind(this)} />
                            :
                            <a className="btn btn-sm btn-link" onClick={this.togglePasswordResetForm.bind(this)}>
                                Glemt passord?
                            </a>
                        }
                    </div>

                    <hr />

                    <div className="center-block">
                        <h3>Jeg vil registrere meg</h3>
                        <Register />
                    </div>

                    <br />
                    <br />

                    <div className="center-block">
                        <a href="/privacy.html">Privacy policy</a>
                    </div>
                </div>

            </Layout>
        );
    }
}