import React, {Component} from 'react';
import _ from 'lodash';

export default (CupRoute) =>
class CupRoutesList extends Component{
    render(){
        let cupRoutes = this.props.cupRoutes;
        let grades = this.props.grades;
        let gradeById = _.keyBy(grades, 'id');

        cupRoutes = _.sortBy(cupRoutes, [
            cupRoute => gradeById[cupRoute.gradeId].colorSortIndex,
            cupRoute => cupRoute.number
        ]);

        return (
            <div className="cup-route-list-wrap">
                <div className="cup-route-list">
                    {cupRoutes.map(cupRoute => {
                        let grade = gradeById[cupRoute.gradeId];
                        return <CupRoute cupRoute={cupRoute} grade={grade} key={cupRoute.id} />
                    })}

                    {cupRoutes.length === 0 &&
                    <p>Ingen ruter så langt</p>
                    }
                </div>
            </div>
        )
    }
}