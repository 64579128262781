import React, {Component} from 'react';
import moment from 'moment';
import Spinner from './Spinner';

/**
 * @param {Message} Message
 */
export default (Message) =>
class Doorlog extends Component{
    render(){
        return (
            <div>
                <h4>Dørlogg</h4>

                <Message context="getDoorlog" />

                {this.props.apiResult.getDoorlog.type === 'GET_DOORLOG_ATTEMPT' &&
                <Spinner />
                }

                {this.props.apiResult.getDoorlog.type === 'GET_DOORLOG_SUCCESS' &&
                <table className="table table-striped table-hover table-condensed">
                    <thead>
                        <tr>
                            <th>Tidspunkt</th>
                            <th>Navn</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.doorlog.map((entry, i) =>
                            <tr key={i}>
                                <td>{moment(entry.openedAt).format("DD/MM/YY HH:mm")}</td>
                                <td>{entry.firstname + ' ' + entry.lastname}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                }

            </div>
        );
    }
}