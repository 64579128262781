import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import './css/index.css'
import './css/cup.css'
import './css/modal.css'
import { Provider } from 'react-redux'
import createContainer from './createContainer'
import ReactModal from 'react-modal'

ReactModal.setAppElement('#root')

const ioc = createContainer()
const store = ioc['ReduxStore']
const RouteRenderer = ioc['RouteRenderer']
const rfr = ioc['ReduxFirstRouter']

rfr.initialDispatch()

ReactDOM.render(
    <Provider store={store}>
        <RouteRenderer />
    </Provider>,
    document.getElementById('root')
)