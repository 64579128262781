import React, {Component} from 'react';
import moment from 'moment';
import Link from './Link';

export default (CupRoundEditor) =>
class RoundLink extends Component{
    constructor(props){
        super(props);
        this.state = {
            showEditor: false
        }
    }
    openEditor(){
        this.setState({showEditor: true});
    }
    closeEditor(){
        this.setState({showEditor: false});
    }
    render(){
        let isAdmin = !!this.props.profile.user.isAdmin;
        let cupRound = this.props.cupRound;
        let day = moment(cupRound.startsAt).format('D/M');
        let start = moment(cupRound.startsAt).format('HH:mm');
        let end = moment(cupRound.endsAt).format('HH:mm');

        let path = '/cup/' + encodeURIComponent(this.props.cup.cup.id) + '/round/' + encodeURIComponent(cupRound.id);

        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Link to={path}><h5>{cupRound.name} ({day} {start} - {end})</h5></Link>

                {isAdmin &&
                <small>
                    <button className="btn-link" onClick={this.openEditor.bind(this)}>
                        <span className="fa fa-edit" />
                    </button>
                </small>
                }

                <CupRoundEditor
                    title="Rediger"
                    isOpen={this.state.showEditor}
                    onClose={this.closeEditor.bind(this)}
                    cupRound={cupRound}
                />
            </div>
        );
    }
}