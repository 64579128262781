import CONSTANTS from '../actionTypes';

export default class MessageAction{
    private _run: any

    constructor(run){
        this._run = run;
    }

    add(context, severity, msg){
        this._run(CONSTANTS.MESSAGE_ADD, {context, severity, msg});
    }

    clear(context){
        this._run(CONSTANTS.MESSAGE_CLEAR, {context});
    }
}