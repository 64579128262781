import CONSTANTS from '../actionTypes';

export default class CheckInAction{
    private _run: any
    private _api: any
    private _message: any

    /**
     * @param run
     * @param {Api} api
     * @param {MessageAction} message
     */
    constructor(run, api, message){
        this._run = run;
        this._api = api;
        this._message = message;
    }

    async checkIn(){
        this._run(CONSTANTS.CHECKIN_ATTEMPT);
        this._message.clear('checkIn');

        try{
            let status = await this._api.request('POST', '/api/checkin');
            this._run(CONSTANTS.CHECKIN_SUCCESS, {status});
            this._message.add('checkIn', 'info', 'Sjekket inn!');
        }catch(err){
            this._run(CONSTANTS.CHECKIN_FAILURE, {err});
            this._message.add('checkIn', 'error', err.message);
        }
    }

    async getCheckInStatus(){
        this._run(CONSTANTS.GET_CHECKINSTATUS_ATTEMPT);
        this._message.clear('checkIn');

        try{
            let status = await this._api.request('GET', '/api/checkin');
            this._run(CONSTANTS.GET_CHECKINSTATUS_SUCCESS, {status});
        }catch(err){
            this._run(CONSTANTS.GET_CHECKINSTATUS_FAILURE, {err});
            this._message.add('checkIn', 'error', err.message);
        }
    }

    async getCheckedIn(){
        this._run(CONSTANTS.GET_CHECKEDIN_ATTEMPT);
        this._message.clear('checkedIn');

        try{
            let checkedIn = await this._api.request('GET', '/api/checkedin');
            this._run(CONSTANTS.GET_CHECKEDIN_SUCCESS, {checkedIn});
        }catch(err){
            this._run(CONSTANTS.GET_CHECKEDIN_FAILURE, {err});
            this._message.add('checkedIn', 'error', err.message);
        }
    }
}