import React, {Component} from 'react';
import moment from 'moment';
import Link from './Link';

export default (CupScoreBoard, CupRoutesList, CupRouteEditor) =>
class CupRound extends Component{
    constructor(props){
        super(props);
        this.state = {
            showRouteCreator: false
        };
    }
    openRouteCreator(e){
        e.preventDefault();
        this.setState({showRouteCreator: true});
    }
    closeRouteCreator(e){
        e.preventDefault();
        this.setState({showRouteCreator: false});
    }
    render(){
        let isAdmin = !!this.props.profile.user.isAdmin;
        let cupRound = this.props.cupRound;
        let day = moment(cupRound.startsAt).format('D/M');
        let start = moment(cupRound.startsAt).format('HH:mm');
        let end = moment(cupRound.endsAt).format('HH:mm');

        let tabSelected = this.props.cup.roundTabSelected || 'results';
        let cup = this.props.cup.cup;
        let cupRoundId = cupRound.id;
        let cupRoutes = this.props.cup.routes.filter(route => route.roundId === cupRoundId);
        let cupRouteIds = cupRoutes.map(cupRoute => cupRoute.id);
        let ticks = this.props.cup.ticks.filter(tick => cupRouteIds.includes(tick.routeId));

        let cupLink = '/cup/' + encodeURIComponent(cup.id);
        let roundLink = cupLink + '/round/' + encodeURIComponent(cupRoundId);
        let resultsLink = roundLink + '/results';
        let routesLink = roundLink + '/routes';

        return (
            <div className="cup-round">
                <h2>{cupRound.name} {day} {start} - {end}</h2>

                <ul className="list-inline">
                    <li><Link to={cupLink}>Tilbake</Link></li>
                    <li><Link to={resultsLink}>Resultater</Link></li>
                    <li><Link to={routesLink}>Tick ruter</Link></li>
                </ul>

                <hr />

                {tabSelected === 'results' &&
                <div>
                    <h2>Resultater</h2>
                    <CupScoreBoard ticks={ticks} />
                </div>
                }

                {tabSelected === 'routes' &&
                <div>
                    <h2>Tick ruter</h2>
                    <CupRoutesList cupRoutes={cupRoutes} />

                    {isAdmin &&
                    <div>
                        <br />

                        <button className="btn btn-default btn-sm" onClick={this.openRouteCreator.bind(this)}>
                            <span className="fa fa-plus" /> Ny rute
                        </button>

                        <CupRouteEditor
                            title="Ny rute"
                            isOpen={this.state.showRouteCreator}
                            onClose={this.closeRouteCreator.bind(this)}
                            cupRoute={{roundId: cupRoundId}}
                        />

                    </div>
                    }
                </div>
                }

            </div>
        );
    }
}