import React, {Component} from 'react';

/**
 * @param {MessageAction} messageAction
 */
export default (messageAction) =>
class Message extends Component{
    render(){
        let context = this.props.context;
        let message = this.props.message;
        let onClear = () => messageAction.clear(context)
        let contextMessage = message[context];

        if(!contextMessage || !contextMessage.msg){
            return <div></div>;
        }

        let severityClass = 'alert-info';
        if(contextMessage.severity  === 'error'){
            severityClass = 'alert-danger';
        }

        severityClass = 'alert ' + severityClass + ' alert-dismissible';

        return (
            <div className={severityClass} onClick={onClear}>
                <button type="button" className="close" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                {contextMessage.msg}
            </div>
        );
    }
}