import React, {Component} from 'react';
import GRADE_SCORES from '../util/gradeScores';
import GRADE_COLORS from '../util/gradeColors';

/**
 * @param {CupAction} cupAction
 * @param {CupRouteEditor} CupRouteEditor
 */
export default (cupAction, CupRouteEditor) =>
class CupRoute extends Component{
    constructor(props){
        super(props);
        this.state = {
            showDetails: false,
            showEditor: false
        };
    }
    onTick(){
        cupAction.tick(this.props.cupRoute.id);
    }
    onUntick(tickId){
        cupAction.untick(tickId);
    }
    onToggleDetails(){
        this.setState((state) => {
            return {...state, showDetails: !state.showDetails};
        });
    }
    openEditor(){
        this.setState({showEditor: true});
    }
    closeEditor(){
        this.setState({showEditor: false});
    }
    render(){
        let cupRoute = this.props.cupRoute;
        let grade = this.props.grade;

        let ticks = this.props.cup.ticks.filter(tick => tick.routeId === cupRoute.id);
        let userTick = ticks.find(tick => tick.userId === this.props.profile.user.id);
        let tickId = userTick ? userTick.id : null;
        let userHasTicked = !!userTick;

        let isTicking = this.props.apiResult.cupTick.type === 'CUP_TICK_ATTEMPT' && this.props.apiResult.cupTick.routeId === cupRoute.id;
        let isUnticking = this.props.apiResult.cupUntick.type === 'CUP_UNTICK_ATTEMPT' && this.props.apiResult.cupUntick.tickId === tickId;

        let tickIsLoading = isTicking || isUnticking;

        let score = GRADE_SCORES[grade.color];
        let colorBoxStyle = GRADE_COLORS[grade.color];

        let onClickTickToggle = this.onTick.bind(this);
        let tickBox = <span className="fa fa-square-o"></span>;
        if(userHasTicked){
            onClickTickToggle = this.onUntick.bind(this, tickId);
            tickBox = <span className="fa fa-check-square-o"></span>;
        }
        if(tickIsLoading){
            onClickTickToggle = () => {};
            tickBox = <span className="fa fa-spinner fa-spin"></span>;
        }

        return (
            <div className="cup-route-row">
                <div className="cup-route-overview">
                    <div className="cup-route-col" style={colorBoxStyle}>
                        {cupRoute.number}
                    </div>
                    <div className="cup-route-col cup-route-tick-toggle" onClick={onClickTickToggle}>
                        {tickBox}
                    </div>
                    <div className="cup-route-col" style={{fontSize: 14}}>
                        {grade.name}
                    </div>
                    <div className="cup-route-col" style={{fontSize: 14}}>
                        {ticks.length} ticks
                    </div>
                    <div className="cup-route-col cup-route-row-expander" onClick={this.onToggleDetails.bind(this)}>
                        <span className="fa fa-caret-down"></span>
                    </div>
                </div>
                {this.state.showDetails &&
                <div className="cup-route-details">
                    <h4>Gradering</h4>
                    {grade.name} / {grade.description}

                    <h4>Poeng</h4>
                    {score}

                    <h4>Bestigninger</h4>
                    {ticks.map(tick => {
                        return (
                            <div key={tick.id}>
                                {tick.firstname} {tick.lastname}
                            </div>
                        );
                    })}

                    {!!this.props.profile.user.isAdmin &&
                        <div>
                            <hr/>

                            <button className="btn btn-default btn-sm" onClick={this.openEditor.bind(this)}>
                                <span className="fa fa-edit" /> Rediger
                            </button>

                            <CupRouteEditor
                                title="Rediger"
                                isOpen={this.state.showEditor}
                                onClose={this.closeEditor.bind(this)}
                                cupRoute={cupRoute}
                            />

                        </div>
                    }
                </div>
                }
            </div>
        );
    }
}