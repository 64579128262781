import CONSTANTS from '../actionTypes';
import _ from 'lodash'

export default class CupAction{
    private _run: any
    private _api: any
    private _message: any
    private _getState: any

    /**
     * @param run
     * @param {Api} api
     * @param {MessageAction} message
     * @param getState
     */
    constructor(run, api, message, getState){
        this._run = run;
        this._api = api;
        this._message = message;
        this._getState = getState
    }

    async reload(){
        if(!_.isEmpty(this._getState().cup.cup)){
            this.loadCup()
        }
    }

    async loadCup(cupId = 'current'){
        this._run(CONSTANTS.GET_CUP_ATTEMPT);

        try{
            let data = await this._api.request('GET', '/api/cup/' + encodeURIComponent(cupId));
            this._run(CONSTANTS.GET_CUP_SUCCESS, {data});
        }catch(err){
            this._run(CONSTANTS.GET_CUP_FAILURE, {err});
            this._message.add('general', 'error', err.message);
        }
    }

    async tick(routeId){
        this._run(CONSTANTS.CUP_TICK_ATTEMPT, {routeId});

        try{
            let tick = await this._api.request('POST', '/api/cup-tick', {routeId});
            this._run(CONSTANTS.CUP_TICK_SUCCESS, {tick});
        }catch(err){
            this._run(CONSTANTS.CUP_TICK_FAILURE, {err});
            this._message.add('general', 'error', err.message);
        }
    }

    async untick(tickId){
        this._run(CONSTANTS.CUP_UNTICK_ATTEMPT, {tickId});

        try{
            await this._api.request('DELETE', '/api/cup-tick/' + encodeURIComponent(tickId));
            this._run(CONSTANTS.CUP_UNTICK_SUCCESS, {tickId});
        }catch(err){
            this._run(CONSTANTS.CUP_UNTICK_FAILURE, {err});
            this._message.add('general', 'error', err.message);
        }
    }

    async createCup(data){
        this._run(CONSTANTS.CREATE_CUP_ATTEMPT);

        try{
            let cup = await this._api.request('POST', '/api/admin/cup', data);
            this._run(CONSTANTS.CREATE_CUP_SUCCESS, {cup});
        }catch(err){
            this._run(CONSTANTS.CREATE_CUP_FAILURE, {err});
            this._message.add('general', 'error', err.message);
        }
    }

    async updateCup(data, cupId){
        this._run(CONSTANTS.UPDATE_CUP_ATTEMPT, {cupId});

        try{
            let cup = await this._api.request('PATCH', '/api/admin/cup/' + encodeURIComponent(cupId), data);
            this._run(CONSTANTS.UPDATE_CUP_SUCCESS, {cup});
        }catch(err){
            this._run(CONSTANTS.UPDATE_CUP_FAILURE, {err});
            this._message.add('general', 'error', err.message);
        }
    }

    async destroyCup(cupId){
        this._run(CONSTANTS.DESTROY_CUP_ATTEMPT, {cupId});

        try{
            await this._api.request('DELETE', '/api/admin/cup/' + encodeURIComponent(cupId));
            this._run(CONSTANTS.DESTROY_CUP_SUCCESS, {cupId});
        }catch(err){
            this._run(CONSTANTS.DESTROY_CUP_FAILURE, {err});
            this._message.add('general', 'error', err.message);
        }
    }

    async createCupRound(data){
        this._run(CONSTANTS.CREATE_CUP_ROUND_ATTEMPT);

        try{
            let cupRound = await this._api.request('POST', '/api/admin/cup-round', data);
            this._run(CONSTANTS.CREATE_CUP_ROUND_SUCCESS, {cupRound});
        }catch(err){
            this._run(CONSTANTS.CREATE_CUP_ROUND_FAILURE, {err});
            this._message.add('general', 'error', err.message);
        }
    }

    async updateCupRound(data, cupRoundId){
        this._run(CONSTANTS.UPDATE_CUP_ROUND_ATTEMPT, {cupRoundId});

        try{
            let cupRound = await this._api.request('PATCH', '/api/admin/cup-round/' + encodeURIComponent(cupRoundId), data);
            this._run(CONSTANTS.UPDATE_CUP_ROUND_SUCCESS, {cupRound});
        }catch(err){
            this._run(CONSTANTS.UPDATE_CUP_ROUND_FAILURE, {err});
            this._message.add('general', 'error', err.message);
        }
    }

    async destroyCupRound(cupRoundId){
        this._run(CONSTANTS.DESTROY_CUP_ROUND_ATTEMPT, {cupRoundId});

        try{
            await this._api.request('DELETE', '/api/admin/cup-round/' + encodeURIComponent(cupRoundId));
            this._run(CONSTANTS.DESTROY_CUP_ROUND_SUCCESS, {cupRoundId});
        }catch(err){
            this._run(CONSTANTS.DESTROY_CUP_ROUND_FAILURE, {err});
            this._message.add('general', 'error', err.message);
        }
    }

    async createCupRoute(data){
        this._run(CONSTANTS.CREATE_CUP_ROUTE_ATTEMPT);

        try{
            let cupRoute = await this._api.request('POST', '/api/admin/cup-route', data);
            this._run(CONSTANTS.CREATE_CUP_ROUTE_SUCCESS, {cupRoute});
        }catch(err){
            this._run(CONSTANTS.CREATE_CUP_ROUTE_FAILURE, {err});
            this._message.add('general', 'error', err.message);
        }
    }

    async updateCupRoute(data, cupRouteId){
        this._run(CONSTANTS.UPDATE_CUP_ROUTE_ATTEMPT, {cupRouteId});

        try{
            let cupRoute = await this._api.request('PATCH', '/api/admin/cup-route/' + encodeURIComponent(cupRouteId), data);
            this._run(CONSTANTS.UPDATE_CUP_ROUTE_SUCCESS, {cupRoute});
        }catch(err){
            this._run(CONSTANTS.UPDATE_CUP_ROUTE_FAILURE, {err});
            this._message.add('general', 'error', err.message);
        }
    }

    async destroyCupRoute(cupRouteId){
        this._run(CONSTANTS.DESTROY_CUP_ROUTE_ATTEMPT, {cupRouteId});

        try{
            await this._api.request('DELETE', '/api/admin/cup-route/' + encodeURIComponent(cupRouteId));
            this._run(CONSTANTS.DESTROY_CUP_ROUTE_SUCCESS, {cupRouteId});
        }catch(err){
            this._run(CONSTANTS.DESTROY_CUP_ROUTE_FAILURE, {err});
            this._message.add('general', 'error', err.message);
        }
    }
}