import CONSTANTS from '../actionTypes';

let initialState = {
    reservations: []
};

export default function(state = initialState, action){
    if(action.type === CONSTANTS.LOAD_RESERVATIONS_SUCCESS){
        return {...state, reservations: action.reservations};
    }
    if(action.type === CONSTANTS.CREATE_RESERVATION_SUCCESS){
        return {...state, reservations: [...state.reservations, action.reservation]}
    }
    if(action.type === CONSTANTS.DELETE_RESERVATION_SUCCESS){
        return {...state, reservations: state.reservations.filter(r => r.id !== action.id)}
    }

    return state;
}