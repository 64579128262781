import React, {Component} from 'react';
import Link from './Link';
import { AcceptTerms } from "./AcceptTerms";
import ProfileAction from "../actions/ProfileAction";
import { Profile, User } from '../types'

type Props = {
    profile: Profile
}

const needsToAcceptTerms = (user: User): boolean => {
    const requiredFrom = new Date('2021-09-15 00:00:00')
    const isRequired = new Date(user.createdAt) > requiredFrom
    return !(user.hasAcceptedTerms === 1) && isRequired
}

/**
 * @param {ProfileAction} profileAction
 * @param {Message} Message
 */
export default (profileAction: ProfileAction, Message) =>
class Layout extends Component<Props> {
    render(){
        let user = this.props.profile.user;
        let isLoggedIn = !!user.id;
        let isAdmin = !!user.isAdmin;

        let homeLink = '/';
        if(isLoggedIn){
            homeLink = '/dashboard';
        }

        if (isLoggedIn && needsToAcceptTerms(user)) {
            return (
                <div className="app container">
                    <AcceptTerms
                        onConfirm={profileAction.acceptTerms}
                    />
                </div>
            )
        }

        return (
            <div className="app container">

                <Link to={homeLink}>
                    <h1>BULDREKONTORET</h1>
                </Link>

                {isLoggedIn &&
                <ul className="list-inline">
                    <li><Link to="/dashboard">Dashboard</Link></li>
                    <li><Link to="/cup/current">Cup</Link></li>
                    {isAdmin &&
                    <li><Link to='/admin'>Admin</Link></li>
                    }
                </ul>
                }

                <br />

                <Message context="general" />

                {this.props.children}

                <br />
                <br />

            </div>
        );
    }
}