import CONSTANTS from '../actionTypes';

export default class GradeAction{
    private _run: any
    private _api: any
    private _message: any

    /**
     * @param run
     * @param {Api} api
     * @param {MessageAction} message
     */
    constructor(run, api, message){
        this._run = run;
        this._api = api;
        this._message = message;
    }

    async loadGrades(){
        this._run(CONSTANTS.GET_GRADES_ATTEMPT);

        try{
            let grades = await this._api.request('GET', '/api/grades');
            this._run(CONSTANTS.GET_GRADES_SUCCESS, {grades});
        }catch(err){
            this._run(CONSTANTS.GET_GRADES_FAILURE, {err});
            this._message.add('general', 'error', err.message);
        }
    }
}