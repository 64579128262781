import React, {Component} from 'react';

export default (Layout, CupRound) =>
class RouteCupRound extends Component{
    render(){
        let {rounds, roundId} = this.props.cup;
        let cupRound = rounds.find(round => round.id === roundId);

        if(!cupRound){
            return null;
        }

        return (
            <Layout>

                <CupRound cupRound={cupRound} />

            </Layout>
        );
    }
}