import React from 'react'

export type AcceptTermsProps = {
    onConfirm: () => void
}

export const AcceptTerms = (props: AcceptTermsProps) => {
    return (
        <div>
            <h4>Egenerklæring for buldring på Buldrekontoret</h4>

            <p>Jeg bekrefter jeg at jeg er innforstått med følgende:</p>

            <ul style={{ textAlign: 'start', maxWidth: 500, margin: 'auto' }} >
                <li>
                    At alt jeg foretar meg, er <b>på eget ansvar og risiko</b>, og at det ikke er noen andre som har ansvar for det jeg foretar meg.
                </li>
                <li>
                    At buldring er en risikosport, og at det alltid vil være en mulighet for at jeg kan pådra meg skader av ulik art. Dette inkluderer fallskader ved en eventuell ulykke!
                </li>
                <li>
                    At alt utstyr for å skru ruter forutsetter riktig bruk for å fungere sikkert, og at jeg vet hvordan dette skal brukes.
                </li>
                <li>
                    At jeg er åpen for å motta rettledning hvis noen mener jeg utfører noe sikkerhetsmessig feil.
                </li>
                <li>
                    At hvis jeg er under 16 år, må jeg ha tillatelse og underskrevet attest fra foresatte.
                </li>
            </ul>

            <button className="btn btn-lg btn-success" onClick={props.onConfirm}>Bekreft</button>
        </div>
    )
}