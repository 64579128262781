import React, {Component} from 'react';
import Spinner from './Spinner';
import moment from 'moment';

/**
 * @param {Message} Message
 */
export default (Message) =>
class CheckedIn extends Component{
    timeSince(checkIn){
        let diff = moment().diff(checkIn.checkedIn, "m");
        let minutes = diff % 60;
        let hours = (diff - minutes) / 60;
        return hours + "h " + minutes + "m siden";
    }
    expired(checkIn){
        return moment().isAfter(checkIn.expiresAt);
    }
    render(){
        return (
            <div>
                <p>
                    Kontormedarbeidere som nylig har sjekket inn:
                </p>

                <Message context="checkedIn" />

                {this.props.apiResult.getCheckedIn.type === 'GET_CHECKEDIN_ATTEMPT' &&
                <Spinner/>
                }

                {this.props.apiResult.getCheckedIn.type === 'GET_CHECKEDIN_SUCCESS' &&
                <table className="table table-striped table-hover table-condensed">
                    <thead>
                        <tr>
                            <th>Sjekket inn</th>
                            <th>Navn</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.checkedIn.map((checkIn) =>
                            <tr key={checkIn.id} style={{fontStyle: this.expired(checkIn) ? 'italic':'normal'}}>
                                <td>{this.timeSince(checkIn)}</td>
                                <td>{checkIn.displayName}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                }

            </div>
        );
    }
}