import CONSTANTS from '../actionTypes';

let initialState = [];

export default function(state = initialState, action){
    if(action.type === CONSTANTS.GET_DOORLOG_SUCCESS){
       return action.doorlog;
    }

    return state;
}