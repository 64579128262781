import React, {Component} from 'react';

/**
 * @param {PasswordResetAction} passwordResetAction
 * @param {Message} Message
 * @param {Layout} Layout
 */
export default (passwordResetAction, Message, Layout) =>
class RoutePasswordReset extends Component{
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            passwordRepeat: ''
        };
    }
    onChangeInput(e){
        let state = {};
        state[e.target.name] = e.target.value;
        this.setState(state);
    }
    onSubmit(e){
        e.preventDefault();
        passwordResetAction.resetPassword(this.state, this.props.passwordResetToken);
    }
    render(){
        return (
            <Layout>
                <div className="center-block">
                    <h4>Velg nytt passord</h4>

                    <form onSubmit={this.onSubmit.bind(this)}>
                        <div className="form-group">
                            <input name="password" type="password" placeholder="Passord" className="form-control"
                                   value={this.state.password} onChange={this.onChangeInput.bind(this)} />
                        </div>
                        <div className="form-group">
                            <input name="passwordRepeat" type="password" placeholder="Passord (igjen)" className="form-control"
                                   value={this.state.passwordRepeat} onChange={this.onChangeInput.bind(this)} />
                        </div>

                        <div className="form-group">
                            {this.props.apiResult.resetPassword.type === 'RESET_PASSWORD_ATTEMPT' ?
                                <button className="btn btn-success" disabled="disabled">Lagrer...</button>
                                :
                                <button type="submit" className="btn btn-success">Lagre</button>
                            }
                        </div>

                        <Message context="resetPassword" />
                    </form>
                </div>
            </Layout>
        );
    }
}