import React, {Component} from 'react';
import moment from 'moment';
import ReactModal from 'react-modal';
import ConfirmButton from './ConfirmButton';

/**
 * @param {CupAction} cupAction
 */
export default (cupAction) =>
class CupRoundEditor extends Component{
    constructor(props) {
        super(props);

        let defaults = {
            name: "1",
            startsAt: new Date(),
            endsAt:  new Date(),
            cupId: null
        };

        let state = {...defaults, ...props.cupRound};
        state.startsAt = moment(state.startsAt).format('YYYY-MM-DD HH:mm:ss');
        state.endsAt = moment(state.endsAt).format('YYYY-MM-DD HH:mm:ss');

        this.state = state;
    }
    onChangeInput(e){
        let state = {};
        state[e.target.name] = e.target.value;
        this.setState(state);
    }
    onSubmit(e){
        e.preventDefault();
        let hasId = !!this.state.id;

        let cupRound = {...this.state};
        cupRound.startsAt = moment(this.state.startsAt, 'YYYY-MM-DD HH:mm:ss');
        cupRound.endsAt = moment(this.state.endsAt, 'YYYY-MM-DD HH:mm:ss');

        if(hasId){
            cupAction.updateCupRound(cupRound, this.state.id);
        }else{
            cupAction.createCupRound(cupRound);
        }
    }
    onDelete(e){
        e.preventDefault();
        cupAction.destroyCupRound(this.state.id);
    }
    render(){
        let hasId = !!this.state.id;
        let cupRoundId = this.state.id;
        let apiResult = this.props.apiResult;

        let isCreating = apiResult.createCupRound.type === 'CREATE_CUP_ROUND_ATTEMPT' && !hasId;
        let isUpdating = apiResult.updateCupRound.type === 'UPDATE_CUP_ROUND_ATTEMPT' && apiResult.updateCupRound.cupRoundId === cupRoundId;
        let isDeleting = apiResult.destroyCupRound.type === 'DESTROY_CUP_ROUND_ATTEMPT' && apiResult.destroyCupRound.cupRoundId === cupRoundId;
        let isSaving = isCreating || isUpdating;
        let showDeleteButton = hasId;

        return (
            <ReactModal
                className="Modal__Bootstrap modal-dialog"
                closeTimeoutMS={150}
                isOpen={this.props.isOpen}
                onRequestClose={this.props.onClose}
                contentLabel="Cup runde modal"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" onClick={this.props.onClose}>
                            <span aria-hidden="true">&times;</span>
                            <span className="sr-only">Close</span>
                        </button>
                        <h4 className="modal-title">{this.props.title}</h4>
                    </div>

                    <form onSubmit={this.onSubmit.bind(this)}>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Navn / nummer</label>
                                <input name="name" type="text" placeholder="Navn" className="form-control"
                                       value={this.state.name} onChange={this.onChangeInput.bind(this)} />
                            </div>
                            <div className="form-group">
                                <label>Start</label>
                                <input name="startsAt" type="text" placeholder="Begynner" className="form-control"
                                       value={this.state.startsAt} onChange={this.onChangeInput.bind(this)} />
                            </div>
                            <div className="form-group">
                                <label>Slutt</label>
                                <input name="endsAt" type="text" placeholder="Slutter" className="form-control"
                                       value={this.state.endsAt} onChange={this.onChangeInput.bind(this)} />
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" onClick={this.props.onClose}>Lukk</button>
                            {showDeleteButton &&
                            <div className="pull-left">
                                {this.renderDeleteButton(isDeleting)}
                            </div>
                            }
                            {this.renderSaveButton(isSaving)}
                        </div>
                    </form>

                </div>
            </ReactModal>
        );
    }
    renderSaveButton(isSaving){
        if(isSaving){
            return <button className="btn btn-success" disabled="disabled">Lagrer...</button>;
        }

        return <button type="submit" className="btn btn-success">Lagre</button>;
    }
    renderDeleteButton(isDeleting){
        if(isDeleting){
            return <button className="btn btn-danger" disabled="disabled">Sletter...</button>;
        }

        return <ConfirmButton className="btn btn-danger" onClick={this.onDelete.bind(this)}>Slett</ConfirmButton>
    }
}