import React, {Component} from 'react';

export default (Layout, CupScoreBoardBig) =>
class RouteCupRoundLive extends Component{
    render(){
        let {rounds, roundId} = this.props.cup;
        let cupRound = rounds.find(round => round.id === roundId);

        if(!cupRound){
            return null;
        }

        let cupRoundId = cupRound.id;
        let cupRoutes = this.props.cup.routes.filter(route => route.roundId === cupRoundId);
        let cupRouteIds = cupRoutes.map(cupRoute => cupRoute.id);
        let ticks = this.props.cup.ticks.filter(tick => cupRouteIds.includes(tick.routeId));

        return (
            <CupScoreBoardBig ticks={ticks} />
        );
    }
}