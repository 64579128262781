import CONSTANTS from '../actionTypes';

let initialState = null;

export default function(state = initialState, action){
    if(action.type === CONSTANTS.ROUTE_PASSWORD_RESET){
        return action.payload.token;
    }

    return state;
}