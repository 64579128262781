import {connectRoutes} from 'redux-first-router'
import {composeWithDevTools} from 'redux-devtools-extension'
import {applyMiddleware, compose, createStore} from 'redux'
import createReducer from '../createReducer'
import Api from '../util/Api'
import WebSocketClient from '../util/WebSocketClient'
import WebSocketEventHandler from '../util/WebSocketEventHandler'
import CONSTANTS from '../actionTypes'

export default ioc => {
    ioc.service('CONFIG', ioc => {
        let isDev = process.env.NODE_ENV === 'development'

        return {
            WS_URL: isDev ? 'ws://localhost:3001' : 'wss://app.buldrekontoret.com:443',
            FACEBOOK_APP_ID: isDev ? 123231048390378 : 285875165150500
        }
    })

    ioc.service('ReduxStore', ioc => {
        const rfr = ioc['ReduxFirstRouter']

        const otherReducers = {location: rfr.reducer, route: ioc['routeReducer']}
        const reducer = createReducer(otherReducers)

        return createStore(
            reducer,
            composeWithDevTools(
                compose(
                    rfr.enhancer,
                    applyMiddleware(rfr.middleware)
                )
            )
        )
    })

    ioc.service('ReduxFirstRouter', ioc => {
        // NB: Prevent initial dispatch. Do that after container is finished setting up to avoid routing before the ioc container is created.
        return connectRoutes(ioc['RouteMap'], {initialDispatch: false})
    })

    ioc.service('run', ioc => {
        let store = ioc['ReduxStore']

        return (type, data = {}) => {
            try {
                store.dispatch({type, ...data});
            }catch(err){
                console.error(err);
            }
        }
    })

    ioc.service('Api', ioc => new Api())
    ioc.service('WebSocketClient', ioc => {
        let handler = new WebSocketEventHandler(ioc['CupAction'], ioc['ReservationAction'])
        return new WebSocketClient(ioc['CONFIG']['WS_URL'], handler.handleEvent.bind(handler))
    })

    ioc.service('ApiWithLoginCheck', ioc => {
        let api = ioc['Api']
        let message = ioc['MessageAction']
        let run = ioc['run']

        return {
            async request(...args){
                try {
                    return await api.request(...args);
                }catch(err){
                    if(err.needsLogin){
                        run(CONSTANTS.LOGOUT_SUCCESS);
                        run(CONSTANTS.ROUTE_WELCOME);
                        message.add('general', 'error', 'Du må være logget inn');
                    }

                    throw err;
                }
            }
        }
    })
}