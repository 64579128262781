export default class WebSocketClient{
    constructor(url, handler = () => {}){
        this._client = null
        this._url = url
        this._handler = handler
        this._reconnect = true
    }

    isOpen(){
        return this._client !== null && this._client.readyState === WebSocket.OPEN
    }

    ensureStarted(){
        if(this._client !== null){
            return
        }

        this._reconnect = true
        this._connect()
    }

    stop(){
        this._reconnect = false
        this._client.close()
        this._client = null
    }

    send(event){
        if(!this.isOpen()){
            throw new Error('WebSocket connection is not open')
        }

        this._client.send(JSON.stringify(event))
    }

    _connect(){
        this._client = new WebSocket(this._url);

        this._client.onerror = () => {
            this._debug('Connection error');
        };

        this._client.onopen = () => {
            this._debug('WebSocket client connected');
        };

        this._client.onclose = () => {
            if(this._reconnect){
                this._debug('Client closed. Retrying...');

                setTimeout(() => {
                    this._connect()
                }, 5000)
            }else{
                this._debug('Client closed')
            }
        };

        this._client.onmessage = (e) => {
            if(typeof e.data !== 'string'){
                return
            }

            let event = JSON.parse(e.data)
            this._handler(event)
        };
    }

    _debug(msg){
        console.log(msg)
    }
}