import React, {Component} from 'react';
import FacebookLogin from 'react-facebook-login';

/**
 * @param {AuthAction} authAction
 * @param facebookAppId
 * @param {Message} Message
 */
export default (authAction, facebookAppId, Message) =>
class Login extends Component{
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            remember: 1
        };
    }
    onChangeInput(e){
        let state = {};
        state[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState(state);
    }
    onSubmitLogin(e){
        e.preventDefault();
        authAction.login(this.state);
    }
    onFacebookLoggedIn(response){
        let data = Object.assign({}, response, {remember: this.state.remember});
        authAction.loginWithFacebook(data);
    }
    render(){
        return (
            <form onSubmit={this.onSubmitLogin.bind(this)}>
                <div className="form-group">
                    <input name="email" type="email" placeholder="E-post" autocomplete="username" className="form-control"
                           value={this.state.email} onChange={this.onChangeInput.bind(this)} />
                </div>
                <div className="form-group">
                    <input name="password" type="password" placeholder="Passord" autocomplete="current-password" className="form-control"
                           value={this.state.password} onChange={this.onChangeInput.bind(this)} />
                </div>
                <div className="checkbox">
                    <label>
                        <input name="remember" type="checkbox" checked={this.state.remember} onChange={this.onChangeInput.bind(this)} /> Forbli innlogget
                    </label>
                </div>

                <div className="form-group">
                    {this.props.apiResult.login.type === 'LOGIN_ATTEMPT' ?
                        <button className="btn btn-success" disabled="disabled">Logger inn...</button>
                        :
                        <button type="submit" className="btn btn-success">Logg inn</button>
                    }
                    &nbsp;
                    <FacebookLogin
                        appId={facebookAppId}
                        autoLoad={false}
                        fields="name,email"
                        scope="public_profile,email"
                        disableMobileRedirect={true}
                        icon="fa-facebook"
                        cssClass="btn btn-primary fb-button"
                        textButton=" Logg inn med facebook"
                        callback={this.onFacebookLoggedIn.bind(this)}
                    />

                </div>

                <Message context="login" />

            </form>
        );
    }
}