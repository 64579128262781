import React, {Component} from 'react'

const gifs = [
    'https://media.giphy.com/media/10G4UQjEoExKuc/giphy.gif',
    'https://media.giphy.com/media/l2YWzcnnPFGF7SOYg/giphy.gif',
    'https://media.giphy.com/media/Tb2i75AlI926I/giphy.gif',
    'https://media.giphy.com/media/eb7C5lHhOd8t2/giphy.gif',
    'https://media.giphy.com/media/v2JtuJH1n3cT6/giphy.gif',
    'https://media.giphy.com/media/I7kkegrRyNrk4/giphy.gif',
    'https://media.giphy.com/media/3xz2Bu76X2laXkT636/giphy.gif',
    'https://media.giphy.com/media/3o6MbiM0MzgvxRxrI4/giphy.gif',
    'https://media.giphy.com/media/3o6QL6BkYYlWuu66oE/giphy.gif',
];

/**
 * @param {DoorAction} doorAction
 * @param {Message} Message
 */
export default (doorAction, Message) =>
class DoorOpener extends Component{
    constructor(props){
        super(props);
        this.state = {
            flashGif: false
        }
    }
    componentWillReceiveProps(nextProps){
        let currType = this.props.apiResult.openDoor.type;
        let nextType = nextProps.apiResult.openDoor.type;
        if(currType !== nextType && nextType === 'OPEN_DOOR_ATTEMPT'){
            let gifNumber = Math.floor(Math.random()*gifs.length);
            let flashGif = gifs[gifNumber];
            this.setState({flashGif});
            setTimeout(() => {
                this.setState({flashGif: false});
            }, 6000);
        }
    }
    onClickOpen() {
        doorAction.openDoor();
    }
    render(){
        return (
            <div>

                {this.state.flashGif !== false ?
                    <div>
                        <img src={this.state.flashGif} style={{maxWidth: 300}} alt=""/>
                        <br />
                        <br />
                    </div>
                    :
                    <div className="form-group">
                        <button className="btn btn-lg btn-success" onClick={this.onClickOpen.bind(this)}>Åpne døra</button>
                    </div>
                }

                <Message context="openDoor" />

            </div>
        );
    }
}