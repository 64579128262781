import React, {Component} from 'react';

export default class ConfirmButton extends Component{
    constructor(props){
        super(props);
        this.state = {
            askForConfirm: false,
        };
    }
    onAskForConfirm(e){
        e.preventDefault();
        this.setState({askForConfirm: true});
    }
    onConfirm(e){
        e.preventDefault();
        this.props.onClick(e);
        this.setState({askForConfirm: false});
    }
    onAbort(e){
        e.preventDefault();
        this.setState({askForConfirm: false});
    }
    render(){
        if(this.state.askForConfirm){
            return (
                <div className="btn-group">
                    <button {...this.props} onClick={this.onConfirm.bind(this)}>Sikker ?</button>
                    <button {...this.props} className="btn btn-default" onClick={this.onAbort.bind(this)}>Avbryt</button>
                </div>
            );
        }else{
            return (
                <button {...this.props} onClick={this.onAskForConfirm.bind(this)}>{this.props.children}</button>
            );
        }
    }
}