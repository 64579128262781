export default {
    'green': {
        color: '#FFF',
        backgroundColor: '#18cb00'
    },
    'blue': {
        color: '#FFF',
        backgroundColor: '#0081ff'
    },
    'yellow': {
        color: '#333',
        backgroundColor: '#fff700'
    },
    'red': {
        color: '#f3fffd',
        backgroundColor: '#ff0c00'
    },
    'black': {
        color: '#FFF',
        backgroundColor: '#0c0c0c'
    },
    'white': {
        color: '#333',
        backgroundColor: '#f8f8f8'
    },
    'silver': {
        color: '#333',
        backgroundColor: '#e1e1e1'
    }
};