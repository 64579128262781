import CONSTANTS from '../actionTypes';

export default class RegisterAction{
    private _run: any
    private _api: any
    private _message: any

    /**
     * @param run
     * @param {Api} api
     * @param {MessageAction} message
     */
    constructor(run, api, message){
        this._run = run;
        this._api = api;
        this._message = message;
    }

    async register(data){
        this._run(CONSTANTS.REGISTER_ATTEMPT);
        this._message.clear('register');

        try{
            let res = await this._api.request('POST', '/api/register', data);
            this._run(CONSTANTS.REGISTER_SUCCESS, {res, data});
            this._message.add('register', 'info', 'Registrering var vellykket. Sjekk eposten din for å bekrefte');
        }catch(err){
            this._run(CONSTANTS.REGISTER_FAILURE, {err});
            this._message.add('register', 'error', err.message);
        }
    }

    async registerConfirm(code){
        this._run(CONSTANTS.REGISTER_CONFIRM_ATTEMPT);

        try{
            let res = await this._api.request('POST', '/api/register/confirm/' + encodeURIComponent(code));
            this._run(CONSTANTS.REGISTER_CONFIRM_SUCCESS, {res});
        }catch(err){
            this._run(CONSTANTS.REGISTER_CONFIRM_FAILURE, {err});
        }
    }
}