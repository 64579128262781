import CONSTANTS from '../actionTypes';

let initialState = {
    user: {}
};

export default function(state = initialState, action){
    if(action.type === CONSTANTS.GET_PROFILE_SUCCESS){
        return action.profile;
    }

    return state;
}