import CONSTANTS from '../actionTypes';

let initialState = {};

export default function(state = initialState, action){
    if(action.type === CONSTANTS.CHECKIN_SUCCESS){
        return action.status;
    }

    if(action.type === CONSTANTS.GET_CHECKINSTATUS_SUCCESS){
        return action.status;
    }

    return state;
}