import React, {Component} from 'react';
import moment from 'moment';
import {isCheckedIn} from "../util/isCheckedIn";

/**
 * @param {CheckInAction} checkInAction
 * @param {Message} Message
 */
export default (checkInAction, Message) =>
class CheckIn extends Component{
    constructor(props){
        super(props);
        this.state = {time: moment()};
        this.interval = setInterval(this.tick.bind(this), 1000);
    }
    tick(){
        this.setState({time: moment()});
    }
    componentWillUnmount(){
        clearInterval(this.interval);
    }
    onClickCheckIn(){
        checkInAction.checkIn();
    }
    isCheckedIn(){
        return isCheckedIn(this.props.checkInStatus, this.state.time)
    }
    render(){
        return (
            <div>
                <div className="form-group">
                    {this.props.apiResult.checkIn.type === 'CHECKIN_ATTEMPT' ?
                        <button className="btn btn-lg btn-default" disabled="disabled">Sjekker inn</button>
                        :
                        <button className="btn btn-lg btn-default" onClick={this.onClickCheckIn.bind(this)} disabled={this.isCheckedIn()}>Sjekk inn</button>
                    }
                </div>

                <Message context="checkIn" />

            </div>
        );
    }
}