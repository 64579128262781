import MessageAction from '../actions/MessageAction'
import AuthAction from '../actions/AuthAction'
import DoorAction from '../actions/DoorAction'
import CheckInAction from '../actions/CheckInAction'
import ProfileAction from '../actions/ProfileAction'
import CupAction from '../actions/CupAction'
import GradeAction from '../actions/GradeAction'
import RegisterAction from '../actions/RegisterAction'
import PasswordResetAction from '../actions/PasswordResetAction'
import ReservationAction from '../actions/ReservationAction'

export default ioc => {
    ioc.service('MessageAction', ioc => new MessageAction(ioc['run']))
    ioc.service('AuthAction', ioc => new AuthAction(ioc['run'], ioc['ApiWithLoginCheck'], ioc['MessageAction']))
    ioc.service('DoorAction', ioc => new DoorAction(ioc['run'], ioc['ApiWithLoginCheck'], ioc['MessageAction']))
    ioc.service('CheckInAction', ioc => new CheckInAction(ioc['run'], ioc['ApiWithLoginCheck'], ioc['MessageAction']))
    ioc.service('ProfileAction', ioc => new ProfileAction(ioc['run'], ioc['ApiWithLoginCheck'], ioc['MessageAction']))
    ioc.service('CupAction', ioc => new CupAction(ioc['run'], ioc['ApiWithLoginCheck'], ioc['MessageAction'], ioc['ReduxStore'].getState))
    ioc.service('GradeAction', ioc => new GradeAction(ioc['run'], ioc['ApiWithLoginCheck'], ioc['MessageAction']))
    ioc.service('RegisterAction', ioc => new RegisterAction(ioc['run'], ioc['ApiWithLoginCheck'], ioc['MessageAction']))
    ioc.service('PasswordResetAction', ioc => new PasswordResetAction(ioc['run'], ioc['ApiWithLoginCheck'], ioc['MessageAction']))
    ioc.service('ReservationAction', ioc => new ReservationAction(ioc['run'], ioc['ApiWithLoginCheck'], ioc['MessageAction'], ioc['ReduxStore'].getState))
}