import CONSTANTS from '../actionTypes';

let initialState = [];

export default function(state = initialState, action){
    if(action.type === CONSTANTS.GET_GRADES_SUCCESS){
        return action.grades;
    }

    return state;
}