import { connect } from 'react-redux'
import _ from 'lodash'

import CheckedIn from '../components/CheckedIn'
import CheckIn from '../components/CheckIn'

import CupRound from '../components/CupRound'
import CupRoundEditor from '../components/CupRoundEditor'
import CupRoundLink from '../components/CupRoundLink'
import CupRoute from '../components/CupRoute'
import CupRouteEditor from '../components/CupRouteEditor'
import CupRoutesList from '../components/CupRoutesList'
import CupScoreBoard from '../components/CupScoreBoard'
import CupScoreBoardBig from '../components/CupScoreBoardBig'

import Message from '../components/Message'
import Doorlog from '../components/Doorlog'
import DoorOpener from '../components/DoorOpener'
import Layout from '../components/Layout'
import Login from '../components/Login'
import Logout from '../components/Logout'
import PasswordResetEmailForm from '../components/PasswordResetEmailForm'
import Register from '../components/Register'

import RouteAdmin from '../components/RouteAdmin'
import RouteCup from '../components/RouteCup'
import RouteCupRound from '../components/RouteCupRound'
import RouteCupRoundLive from '../components/RouteCupRoundLive'
import RouteDashboard from '../components/RouteDashboard'
import RoutePasswordReset from '../components/RoutePasswordReset'
import RouteRegisterConfirm from '../components/RouteRegisterConfirm'
import RouteWelcome from '../components/RouteWelcome'
import RouteReservation from '../components/RouteReservation'
import RouteRenderer from '../components/RouteRenderer'

const connectProps = (mapping = {}) => {
    return connect(state => {
        return _.entries(mapping).reduce((props, [from, to]) => {
            _.set(props, to, _.get(state, from))
            return props
        }, {})
    })
}

export default ioc => {
    ioc.service('CheckedIn', ioc => connectProps({'apiResult': 'apiResult', 'checkedIn': 'checkedIn'})(CheckedIn(ioc['Message'])))
    ioc.service('CheckIn', ioc => connectProps({'apiResult': 'apiResult', 'checkInStatus': 'checkInStatus'})(CheckIn(ioc['CheckInAction'], ioc['Message'])))

    ioc.service('CupRound', ioc => connectProps({'profile': 'profile', 'cup': 'cup'})(CupRound(ioc['CupScoreBoard'], ioc['CupRoutesList'], ioc['CupRouteEditor'])))
    ioc.service('CupRoundEditor', ioc => connectProps({'apiResult': 'apiResult'})(CupRoundEditor(ioc['CupAction'])))
    ioc.service('CupRoundLink', ioc => connectProps({'profile': 'profile', 'cup': 'cup'})(CupRoundLink(ioc['CupRoundEditor'])))
    ioc.service('CupRoute', ioc => connectProps({'apiResult': 'apiResult', 'profile': 'profile', 'cup': 'cup'})(CupRoute(ioc['CupAction'], ioc['CupRouteEditor'])))
    ioc.service('CupRouteEditor', ioc => connectProps({'apiResult': 'apiResult', 'grades': 'grades'})(CupRouteEditor(ioc['CupAction'])))
    ioc.service('CupRoutesList', ioc => connectProps({'grades': 'grades'})(CupRoutesList(ioc['CupRoute'])))
    ioc.service('CupScoreBoard', ioc => connectProps({'cup': 'cup', 'grades': 'grades'})(CupScoreBoard()))
    ioc.service('CupScoreBoardBig', ioc => connectProps({'cup': 'cup', 'grades': 'grades'})(CupScoreBoardBig()))

    ioc.service('Message', ioc => connectProps({'message': 'message'})(Message(ioc['MessageAction'])))
    ioc.service('Doorlog', ioc => connectProps({'apiResult': 'apiResult', 'doorlog': 'doorlog'})(Doorlog(ioc['Message'])))
    ioc.service('DoorOpener', ioc => connectProps({'apiResult': 'apiResult', 'checkInStatus': 'checkInStatus'})(DoorOpener(ioc['DoorAction'], ioc['Message'])))
    ioc.service('Layout', ioc => connectProps({'profile': 'profile'})(Layout(ioc['ProfileAction'], ioc['Message'])))
    ioc.service('Login', ioc => connectProps({'apiResult': 'apiResult'})(Login(ioc['AuthAction'], ioc['CONFIG']['FACEBOOK_APP_ID'], ioc['Message'])))
    ioc.service('Logout', ioc => connectProps({'apiResult': 'apiResult'})(Logout(ioc['AuthAction'], ioc['Message'])))
    ioc.service('PasswordResetEmailForm', ioc => connectProps({'apiResult': 'apiResult'})(PasswordResetEmailForm(ioc['PasswordResetAction'], ioc['Message'])))
    ioc.service('Register', ioc => connectProps({'apiResult': 'apiResult'})(Register(ioc['RegisterAction'], ioc['Message'])))

    ioc.service('RouteAdmin', ioc => connectProps()(RouteAdmin(ioc['Layout'], ioc['Doorlog'])))
    ioc.service('RouteCup', ioc => connectProps({'cup': 'cup', 'profile': 'profile'})(RouteCup(ioc['Layout'], ioc['CupRoundEditor'], ioc['CupScoreBoard'], ioc['CupRoundLink'])))
    ioc.service('RouteCupRound', ioc => connectProps({'cup': 'cup'})(RouteCupRound(ioc['Layout'], ioc['CupRound'])))
    ioc.service('RouteCupRoundLive', ioc => connectProps({'cup': 'cup'})(RouteCupRoundLive(ioc['Layout'], ioc['CupScoreBoardBig'])))
    ioc.service('RouteDashboard', ioc => RouteDashboard(ioc['Layout'], ioc['DoorOpener'], ioc['CheckIn'], ioc['CheckedIn'], ioc['Logout']))
    ioc.service('RoutePasswordReset', ioc => connectProps({'apiResult': 'apiResult', 'passwordResetToken': 'passwordResetToken'})(RoutePasswordReset(ioc['PasswordResetAction'], ioc['Message'], ioc['Layout'])))
    ioc.service('RouteRegisterConfirm', ioc => connectProps({'apiResult': 'apiResult'})(RouteRegisterConfirm(ioc['Layout'], ioc['Login'])))
    ioc.service('RouteWelcome', ioc => connectProps()(RouteWelcome(ioc['Layout'], ioc['Login'], ioc['Register'], ioc['PasswordResetEmailForm'])))
    ioc.service('RouteReservation', ioc => connectProps()(RouteReservation(ioc['Layout'], ioc['ReservationAction'])))
    ioc.service('RouteRenderer', ioc => connectProps({'route': 'route'})(RouteRenderer(ioc['RouteToComponentMap'])))
}