import moment, { Moment, unitOfTime } from 'moment'

type Step = {
    size: number
    type: unitOfTime.DurationConstructor
}
export type Reservation = {
    id: number
    startsAt: string
    endsAt: string
    userId: number
}

export const fullDateFormat = 'DD/MM HH:mm'
export const dateFormat = 'DD/MM/YY'
export const timeFormat = 'HH:mm'
export const maxWeeksIntoFuture = 2
export const maxReservationCountPerDay = 1
export const maxReservationsAtAnyTime = 12
export const maxReservationMinutesPerDay = 120
export const maxActiveReservations = 3
export const selectableStep: Step = {size: 60, type: 'minutes'}

const dateRange = (start: Moment, end: Moment, step: Step) => {
    const range: Moment[] = []
    while(start.toDate() < end.toDate()){
        range.push(start)
        start = start.clone().add(step.size, step.type)
    }
    return range
}

export const getRangeToLoad = (startDateStr: string) => {
    return {
        start: moment(startDateStr, dateFormat).startOf('day').toDate(),
        end: moment(startDateStr, dateFormat).add(maxWeeksIntoFuture, 'weeks').add(1, 'day').startOf('day').toDate()
    }
}

export const dateStrToStartAndEnd = (dateStr: string) => {
    return {
        start: moment(dateStr, dateFormat).startOf('day').toDate(),
        end: moment(dateStr, dateFormat).add(1, 'day').startOf('day').toDate()
    }
}

export const isReservationExpired = (reservation: Reservation, now: Date) => {
    return new Date(reservation.endsAt) < now
}

export const isReservationOnDate = (reservation: Reservation, dateStr: string) => {
    const {start, end} = dateStrToStartAndEnd(dateStr)
    const rStartsAt = new Date(reservation.startsAt)
    const rEndsAt = new Date(reservation.endsAt)

    return (rStartsAt >= start && rStartsAt < end) || (rEndsAt >= start && rEndsAt < end)
}

const clampDate = (date: Date, min: Date, max: Date) => {
    if(date < min) return min
    if(date > max) return max
    return date
}

type ReservationCount = {[key: string]: number}
export const getReservationCount = (reservations: Reservation[], dateStr: string, step: Step, stepFormat: string): ReservationCount => {
    const min = moment(dateStr, dateFormat).toDate()
    const max = moment(dateStr, dateFormat).endOf('day').toDate()

    const count: {[key: string]: number} = {}
    reservations.forEach(reservation => {
        const start = clampDate(new Date(reservation.startsAt), min, max)
        const end = clampDate(new Date(reservation.endsAt), min, max)
        const steps = dateRange(moment(start), moment(end), step).map(m => m.format(stepFormat))
        steps.forEach(step => {
            count[step] = (count[step]??0) + 1
        })
    })
    return count
}

export const reservationCountToRanges = (reservationCount: ReservationCount, step: Step) => {
    const range = getTimeRange('00:00:00', '23:59:59', step, timeFormat)
    const ranges: {start: string, end: string, count: number}[] = []
    let currCount = 0
    let currRange: string[] = []
    for(let timeStr of range){
        const count = reservationCount[timeStr] ?? 0
        if(count === currCount){
            currRange.push(timeStr)
        }else{
            if(currRange.length > 0){
                ranges.push({
                    start: currRange[0],
                    end: moment(currRange[currRange.length - 1], timeFormat).add(step.size, step.type).format(timeFormat),
                    count: currCount
                })
            }
            currCount = count
            currRange = [timeStr]
        }
    }
    if(currRange.length > 0){
        ranges.push({
            start: currRange[0],
            end: moment(currRange[currRange.length - 1], timeFormat).add(step.size, step.type).format(timeFormat),
            count: currCount
        })
    }
    return ranges.filter(r => r.count > 0)
}

export const getReservationsForDate = (reservations: Reservation[], dateStr: string): Reservation[] => {
    return reservations.filter(reservation => isReservationOnDate(reservation, dateStr))
}

export const getTimeRange = (startTime: string, endTime: string, step: Step, stepFormat: string) => {
    return dateRange(
        moment('2020-01-01 ' + startTime, 'YYYY-MM-DD HH:mm:ss'),
        moment('2020-01-01 ' + endTime, 'YYYY-MM-DD HH:mm:ss'),
        step
    ).map(m => m.format(stepFormat))
}

const isLegalStartTime = (timeStr: string, reservationCount: ReservationCount, dateStr: string, now: Date) => {
    const hasVacancy = (reservationCount[timeStr]??0) < maxReservationsAtAnyTime
    const isAfterNow = moment(`${dateStr} ${timeStr}`, `${dateFormat} ${timeFormat}`).isAfter(now)
    return hasVacancy && isAfterNow
}

const isLegalEndTime = (timeStr: string, startTimeStr: string, reservationCount: ReservationCount, step: Step) => {
    const momentStart = moment(startTimeStr, timeFormat)
    const momentEnd = moment(timeStr, timeFormat)

    if(momentEnd.toDate() <= momentStart.toDate()){
        return false
    }
    if(momentEnd.diff(momentStart, 'minutes') > maxReservationMinutesPerDay){
        return false
    }
    const hasVacancy = getTimeRange(startTimeStr, timeStr, step, timeFormat)
        .every(str => (reservationCount[str]??0) < maxReservationsAtAnyTime)

    return hasVacancy
}

export const getStartTimes = (reservationCount: ReservationCount, dateStr: string, now: Date) => {
    return getTimeRange('07:00:00', '23:00:00', selectableStep, timeFormat)
        .filter(timeStr => isLegalStartTime(timeStr, reservationCount, dateStr, now))
}

export const getEndTimes = (reservationCount: ReservationCount, startTimeStr: string) => {
    return getTimeRange('07:00:00', '23:59:00', selectableStep, timeFormat)
        .filter(timeStr => isLegalEndTime(timeStr, startTimeStr, reservationCount, selectableStep))
}

export const getDates = () => {
    return dateRange(moment(), moment().add(maxWeeksIntoFuture, 'weeks'), {size: 1, type: 'day'}).map(m => m.format(dateFormat))
}