import CONSTANTS from '../actionTypes';

let initialState = [];

export default function(state = initialState, action){
    if(action.type === CONSTANTS.CHECKIN_SUCCESS){
       return [action.status, ...state];
    }

    if(action.type === CONSTANTS.GET_CHECKEDIN_SUCCESS){
        return action.checkedIn;
    }

    return state;
}