import React from 'react';
import {useSelector} from "react-redux";
import moment from 'moment'
import ReservationAction from '../actions/ReservationAction'
import * as utils from '../util/reservationUtils'
import { dateFormat } from '../util/reservationUtils'

type SelectedState = {
    reservations: utils.Reservation[],
    userId: number
}

const selector = (state): SelectedState => {
    return {
        reservations: state.reservation.reservations,
        userId: state.profile.user.id
    }
}

const DateSelector = (props) => {
    return (
        <select value={props.value} onChange={(e) => props.onChange(e.target.value)} className='form-control'>
            {props.options.map(({name, value}) => {
                return (
                    <option key={value} value={value}>{name}</option>
                )
            })}
        </select>
    )
}
const TimeSelector = (props) => {
    return (
        <select value={props.value} onChange={(e) => props.onChange(e.target.value)} className='form-control inline'>
            {props.options.map(({name, value}) => {
                return (
                    <option key={value} value={value}>{name}</option>
                )
            })}
        </select>
    )
}

const FormSpacing = () => <span style={{paddingRight: 8}}/>

export default (Layout, reservationAction: ReservationAction) => function RouteReservation(){
    const state = useSelector(selector)

    const now = new Date()
    const todayDateStr = moment().format(dateFormat)
    const dates = utils.getDates()
    const [dateStr, setDateStr] = React.useState(dates[0])

    const myReservations: utils.Reservation[] = state.reservations.filter(r => r.userId === state.userId)
    const hasReservationThisDay = myReservations.filter(r => utils.isReservationOnDate(r, dateStr)).length > 0
    const hasMaxReservationsAlready = myReservations.filter(r => new Date(r.startsAt) > now).length >= utils.maxActiveReservations

    const nonExpiredReservations = state.reservations.filter(r => !utils.isReservationExpired(r, now))
    const reservations = utils.getReservationsForDate(nonExpiredReservations, dateStr)
    const reservationCount = utils.getReservationCount(reservations, dateStr, utils.selectableStep, utils.timeFormat)
    const reservationCountRanges = utils.reservationCountToRanges(reservationCount, utils.selectableStep)

    const startTimes = utils.getStartTimes(reservationCount, dateStr, now)
    const [startTimeStr, setStartTimeStr] = React.useState(startTimes[0])

    const endTimes = utils.getEndTimes(reservationCount, startTimeStr)
    const [endTimeStr, setEndTimeStr] = React.useState(endTimes[0])

    const canReserve = startTimes.length > 0 && endTimes.length > 0 && !hasReservationThisDay && !hasMaxReservationsAlready

    React.useEffect(() => {
        if(!endTimes.includes(endTimeStr) && endTimes.length > 0){
            setEndTimeStr(endTimes[0])
        }
    }, [endTimes, endTimeStr])

    React.useEffect(() => {
        if(!startTimes.includes(startTimeStr) && startTimes.length > 0){
            setStartTimeStr(startTimes[0])
        }
    }, [startTimes, startTimeStr])

    React.useEffect(() => {
        const {start, end} = utils.getRangeToLoad(todayDateStr)
        reservationAction.loadReservations(start, end)
    }, [todayDateStr])

    const save = React.useCallback(() => {
        const startsAt = moment(`${dateStr} ${startTimeStr}`, `${utils.dateFormat} ${utils.timeFormat}`).toDate()
        const endsAt = moment(`${dateStr} ${endTimeStr}`, `${utils.dateFormat} ${utils.timeFormat}`).toDate()
        reservationAction.create(startsAt, endsAt)
    }, [startTimeStr, endTimeStr, dateStr])

    const remove = React.useCallback((id: number) => {
        reservationAction.remove(id)
    }, [])

    return (
        <Layout>
            <h4>Reserver tid på buldrekontoret</h4>

            <hr />
            <h5>Velg dag</h5>
            <div className='form-inline'>
                <DateSelector
                    value={dateStr}
                    onChange={setDateStr}
                    options={dates.map(d => ({name: d, value: d}))}
                />
            </div>

            <hr />
            <h5>Reserver tid</h5>

            {!canReserve &&
                <p style={{fontStyle: 'italic'}}>Ingen flere reservasjoner tilgjengelig denne dagen</p>
            }

            {canReserve &&
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} className='form-inline'>
                Fra
                <FormSpacing/>
                <TimeSelector
                    value={startTimeStr}
                    onChange={setStartTimeStr}
                    options={startTimes.map(t => ({name: t, value: t}))}
                />
                <FormSpacing/>
                til
                <FormSpacing/>
                <TimeSelector
                    value={endTimeStr}
                    onChange={setEndTimeStr}
                    options={endTimes.map(t => ({name: t, value: t}))}
                />
                <FormSpacing/>
                <button
                    className='btn btn-success'
                    onClick={save}
                >
                    Reserver
                </button>
            </div>
            }

            <br/>
            <div style={{textAlign: 'start', display: 'flex', justifyContent: 'center'}}>
                <ul className='text-muted' style={{fontSize: 'small'}}>
                    <li>Maks {utils.maxReservationCountPerDay} reservasjon per dag</li>
                    <li>Maks {utils.maxReservationMinutesPerDay/60} timer per dag</li>
                    <li>Maks {utils.maxWeeksIntoFuture} uker frem i tid</li>
                    <li>Maks {utils.maxActiveReservations} aktive reservasjoner</li>
                    <li>Maks {utils.maxReservationsAtAnyTime} klatrere av gangen</li>
                </ul>
            </div>

            {myReservations.length > 0 &&
                <>
                    <hr />
                    <h5>Mine reservasjoner</h5>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <table className='table' style={{width: 300}}>
                            <tbody>
                            {myReservations.map(reservation => {
                                return (
                                    <tr key={reservation.id}>
                                        <td>

                                            {moment(reservation.startsAt).format(utils.fullDateFormat)} - {moment(reservation.endsAt).format(utils.timeFormat)}
                                        </td>
                                        <td>
                                            <button
                                                className='btn btn-danger btn-xs'
                                                onClick={() => remove(reservation.id)}
                                                disabled={new Date(reservation.startsAt) < now}
                                            >
                                                Slett
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </>
            }

            <hr />
            <h5>Reservasjoner denne dagen</h5>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <table className='table' style={{width: 200}}>
                    <tbody>
                        {reservationCountRanges.map(({start, end, count}) => {
                            return (
                                <tr key={start}>
                                    <td>{start} - {end}</td>
                                    <td>{count} {count >= utils.maxReservationsAtAnyTime && <span style={{color: 'red'}}> Fullt</span>}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </Layout>
    )
}