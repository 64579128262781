import CONSTANTS from '../actionTypes';

export default class ProfileAction{
    private _run: any
    private _api: any
    private _message: any

    /**
     * @param run
     * @param {Api} api
     * @param {MessageAction} message
     */
    constructor(run, api, message){
        this._run = run;
        this._api = api;
        this._message = message;
    }

    async loadProfile(){
        this._run(CONSTANTS.GET_PROFILE_ATTEMPT);

        try {
            const profile = await this._api.request('GET', '/api/profile');
            this._run(CONSTANTS.GET_PROFILE_SUCCESS, {profile});
        }catch(err){
            this._run(CONSTANTS.GET_PROFILE_FAILURE, {err});
            this._message.add('general', 'error', err.message);
        }
    }

    acceptTerms = async () => {
        try {
            const profile = await this._api.request('POST', '/api/profile/accept-terms');
            this._run(CONSTANTS.GET_PROFILE_SUCCESS, {profile});
            this._message.add('general', 'success', 'Egenærklæring bekreftet!');
        }catch(err){
            console.log(err)
            this._message.add('general', 'error', err.message);
        }
    }
}