import CONSTANTS from '../actionTypes';

let initialState = {};

export default function(state = initialState, action){
    if(action.type === CONSTANTS.MESSAGE_ADD){
        let message = {};
        message[action.context] = action;
        return {...state, ...message};
    }

    if(action.type === CONSTANTS.MESSAGE_CLEAR){
        let message = {};
        message[action.context] = action;
        return {...state, ...message};
    }

    // Clear all messages when navigating to new route
    if(action.meta && action.meta.location){
        return initialState;
    }

    return state;
}