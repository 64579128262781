import CONSTANTS from '../actionTypes';

export default class PasswordResetAction{
    private _run: any
    private _api: any
    private _message: any

    /**
     * @param run
     * @param {Api} api
     * @param {MessageAction} message
     */
    constructor(run, api, message){
        this._run = run;
        this._api = api;
        this._message = message;
    }

    async sendPasswordResetEmail(data){
        this._run(CONSTANTS.SEND_PASSWORD_RESET_EMAIL_ATTEMPT);
        this._message.clear('sendPasswordResetEmail');

        try{
            let res = await this._api.request('POST', '/api/passwordreset', data);
            this._run(CONSTANTS.SEND_PASSWORD_RESET_EMAIL_SUCCESS, {res, data});
            this._message.add('sendPasswordResetEmail', 'info', 'Følg linken i eposten for å tilbakestille passordet');
        }catch(err){
            this._run(CONSTANTS.SEND_PASSWORD_RESET_EMAIL_FAILURE, {err});
            this._message.add('sendPasswordResetEmail', 'error', err.message);
        }
    }

    async resetPassword(data, token){
        this._run(CONSTANTS.RESET_PASSWORD_ATTEMPT);
        this._message.clear('resetPassword');

        try{
            let res = await this._api.request('POST', '/api/passwordreset/' + encodeURIComponent(token), data);
            this._run(CONSTANTS.RESET_PASSWORD_SUCCESS, {res});
            this._run(CONSTANTS.ROUTE_WELCOME);
            this._message.add('general', 'info', 'Passord oppdatert! Du kan nå logge inn');
        }catch(err){
            this._run(CONSTANTS.RESET_PASSWORD_FAILURE, {err});
            this._message.add('resetPassword', 'error', err.message);
        }
    }
}