import _ from 'lodash'

export default class WebSocketEventHandler{
    /**
     * @param {CupAction} cupAction
     * @param {ReservationAction} reservationAction
     */
    constructor(cupAction, reservationAction){
        this._reloadCup = _.debounce(() => cupAction.reload(), 1000)
        this._reloadReservations = _.debounce(() => reservationAction.reload(), 1000)
    }

    async handleEvent(event){
        if(['TICK_CREATED', 'TICK_DESTROYED'].includes(event.type)){
            this._reloadCup()
        }
        if(['RESERVATION_CREATED', 'RESERVATION_DELETED'].includes(event.type)){
            this._reloadReservations()
        }
    }
}