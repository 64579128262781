export default {
    'MESSAGE_ADD': 'MESSAGE_ADD',
    'MESSAGE_CLEAR': 'MESSAGE_CLEAR',

    'LOGIN_ATTEMPT': 'LOGIN_ATTEMPT',
    'LOGIN_SUCCESS': 'LOGIN_SUCCESS',
    'LOGIN_FAILURE': 'LOGIN_FAILURE',

    'LOGOUT_ATTEMPT': 'LOGOUT_ATTEMPT',
    'LOGOUT_SUCCESS': 'LOGOUT_SUCCESS',
    'LOGOUT_FAILURE': 'LOGOUT_FAILURE',

    'GET_PROFILE_ATTEMPT': 'GET_PROFILE_ATTEMPT',
    'GET_PROFILE_SUCCESS': 'GET_PROFILE_SUCCESS',
    'GET_PROFILE_FAILURE': 'GET_PROFILE_FAILURE',

    'REGISTER_ATTEMPT': 'REGISTER_ATTEMPT',
    'REGISTER_SUCCESS': 'REGISTER_SUCCESS',
    'REGISTER_FAILURE': 'REGISTER_FAILURE',

    'REGISTER_CONFIRM_ATTEMPT': 'REGISTER_CONFIRM_ATTEMPT',
    'REGISTER_CONFIRM_SUCCESS': 'REGISTER_CONFIRM_SUCCESS',
    'REGISTER_CONFIRM_FAILURE': 'REGISTER_CONFIRM_FAILURE',

    'OPEN_DOOR_ATTEMPT': 'OPEN_DOOR_ATTEMPT',
    'OPEN_DOOR_SUCCESS': 'OPEN_DOOR_SUCCESS',
    'OPEN_DOOR_FAILURE': 'OPEN_DOOR_FAILURE',

    'CHECKIN_ATTEMPT': 'CHECKIN_ATTEMPT',
    'CHECKIN_SUCCESS': 'CHECKIN_SUCCESS',
    'CHECKIN_FAILURE': 'CHECKIN_FAILURE',

    'GET_CHECKINSTATUS_ATTEMPT': 'GET_CHECKINSTATUS_ATTEMPT',
    'GET_CHECKINSTATUS_SUCCESS': 'GET_CHECKINSTATUS_SUCCESS',
    'GET_CHECKINSTATUS_FAILURE': 'GET_CHECKINSTATUS_FAILURE',

    'GET_CHECKEDIN_ATTEMPT': 'GET_CHECKEDIN_ATTEMPT',
    'GET_CHECKEDIN_SUCCESS': 'GET_CHECKEDIN_SUCCESS',
    'GET_CHECKEDIN_FAILURE': 'GET_CHECKEDIN_FAILURE',

    'SEND_PASSWORD_RESET_EMAIL_ATTEMPT': 'SEND_PASSWORD_RESET_EMAIL_ATTEMPT',
    'SEND_PASSWORD_RESET_EMAIL_SUCCESS': 'SEND_PASSWORD_RESET_EMAIL_SUCCESS',
    'SEND_PASSWORD_RESET_EMAIL_FAILURE': 'SEND_PASSWORD_RESET_EMAIL_FAILURE',

    'RESET_PASSWORD_ATTEMPT': 'RESET_PASSWORD_ATTEMPT',
    'RESET_PASSWORD_SUCCESS': 'RESET_PASSWORD_SUCCESS',
    'RESET_PASSWORD_FAILURE': 'RESET_PASSWORD_FAILURE',

    'GET_DOORLOG_ATTEMPT': 'GET_DOORLOG_ATEMPT',
    'GET_DOORLOG_SUCCESS': 'GET_DOORLOG_SUCCESS',
    'GET_DOORLOG_FAILURE': 'GET_DOORLOG_FAILURE',

    'GET_CUP_ATTEMPT': 'GET_CUP_ATTEMPT',
    'GET_CUP_SUCCESS': 'GET_CUP_SUCCESS',
    'GET_CUP_FAILURE': 'GET_CUP_FAILURE',

    'CUP_TICK_ATTEMPT': 'CUP_TICK_ATTEMPT',
    'CUP_TICK_SUCCESS': 'CUP_TICK_SUCCESS',
    'CUP_TICK_FAILURE': 'CUP_TICK_FAILURE',

    'CUP_UNTICK_ATTEMPT': 'CUP_UNTICK_ATTEMPT',
    'CUP_UNTICK_SUCCESS': 'CUP_UNTICK_SUCCESS',
    'CUP_UNTICK_FAILURE': 'CUP_UNTICK_FAILURE',

    'GET_GRADES_ATTEMPT': 'GET_GRADES_ATTEMPT',
    'GET_GRADES_SUCCESS': 'GET_GRADES_SUCCESS',
    'GET_GRADES_FAILURE': 'GET_GRADES_FAILURE',

    'CREATE_CUP_ATTEMPT': 'CREATE_CUP_ATTEMPT',
    'CREATE_CUP_SUCCESS': 'CREATE_CUP_SUCCESS',
    'CREATE_CUP_FAILURE': 'CREATE_CUP_FAILURE',

    'UPDATE_CUP_ATTEMPT': 'UPDATE_CUP_ATTEMPT',
    'UPDATE_CUP_SUCCESS': 'UPDATE_CUP_SUCCESS',
    'UPDATE_CUP_FAILURE': 'UPDATE_CUP_FAILURE',

    'DESTROY_CUP_ATTEMPT': 'DESTROY_CUP_ATTEMPT',
    'DESTROY_CUP_SUCCESS': 'DESTROY_CUP_SUCCESS',
    'DESTROY_CUP_FAILURE': 'DESTROY_CUP_FAILURE',

    'CREATE_CUP_ROUND_ATTEMPT': 'CREATE_CUP_ROUND_ATTEMPT',
    'CREATE_CUP_ROUND_SUCCESS': 'CREATE_CUP_ROUND_SUCCESS',
    'CREATE_CUP_ROUND_FAILURE': 'CREATE_CUP_ROUND_FAILURE',

    'UPDATE_CUP_ROUND_ATTEMPT': 'UPDATE_CUP_ROUND_ATTEMPT',
    'UPDATE_CUP_ROUND_SUCCESS': 'UPDATE_CUP_ROUND_SUCCESS',
    'UPDATE_CUP_ROUND_FAILURE': 'UPDATE_CUP_ROUND_FAILURE',

    'DESTROY_CUP_ROUND_ATTEMPT': 'DESTROY_CUP_ROUND_ATTEMPT',
    'DESTROY_CUP_ROUND_SUCCESS': 'DESTROY_CUP_ROUND_SUCCESS',
    'DESTROY_CUP_ROUND_FAILURE': 'DESTROY_CUP_ROUND_FAILURE',

    'CREATE_CUP_ROUTE_ATTEMPT': 'CREATE_CUP_ROUTE_ATTEMPT',
    'CREATE_CUP_ROUTE_SUCCESS': 'CREATE_CUP_ROUTE_SUCCESS',
    'CREATE_CUP_ROUTE_FAILURE': 'CREATE_CUP_ROUTE_FAILURE',

    'UPDATE_CUP_ROUTE_ATTEMPT': 'UPDATE_CUP_ROUTE_ATTEMPT',
    'UPDATE_CUP_ROUTE_SUCCESS': 'UPDATE_CUP_ROUTE_SUCCESS',
    'UPDATE_CUP_ROUTE_FAILURE': 'UPDATE_CUP_ROUTE_FAILURE',

    'DESTROY_CUP_ROUTE_ATTEMPT': 'DESTROY_CUP_ROUTE_ATTEMPT',
    'DESTROY_CUP_ROUTE_SUCCESS': 'DESTROY_CUP_ROUTE_SUCCESS',
    'DESTROY_CUP_ROUTE_FAILURE': 'DESTROY_CUP_ROUTE_FAILURE',

    'LOAD_RESERVATIONS_ATTEMPT': 'LOAD_RESERVATIONS_ATTEMPT',
    'LOAD_RESERVATIONS_SUCCESS': 'LOAD_RESERVATIONS_SUCCESS',
    'LOAD_RESERVATIONS_FAILURE': 'LOAD_RESERVATIONS_FAILURE',

    'CREATE_RESERVATION_ATTEMPT': 'CREATE_RESERVATION_ATTEMPT',
    'CREATE_RESERVATION_SUCCESS': 'CREATE_RESERVATION_SUCCESS',
    'CREATE_RESERVATION_FAILURE': 'CREATE_RESERVATION_FAILURE',

    'DELETE_RESERVATION_ATTEMPT': 'DELETE_RESERVATION_ATTEMPT',
    'DELETE_RESERVATION_SUCCESS': 'DELETE_RESERVATION_SUCCESS',
    'DELETE_RESERVATION_FAILURE': 'DELETE_RESERVATION_FAILURE',

    'ROUTE_WELCOME':          'ROUTE_WELCOME',
    'ROUTE_DASHBOARD':        'ROUTE_DASHBOARD',
    'ROUTE_CUP':              'ROUTE_CUP',
    'ROUTE_CUP_ROUND':        'ROUTE_CUP_ROUND',
    'ROUTE_CUP_ROUND_LIVE':   'ROUTE_CUP_ROUND_LIVE',
    'ROUTE_REGISTER_CONFIRM': 'ROUTE_REGISTER_CONFIRM',
    'ROUTE_PASSWORD_RESET':   'ROUTE_PASSWORD_RESET',
    'ROUTE_RESERVATION':      'ROUTE_RESERVATION',
    'ROUTE_ADMIN':            'ROUTE_ADMIN',
};